import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, Platform } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { MessageType } from '@shared/constants/message-type';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';
import { Observable } from 'rxjs';
import { ChirpyChitChatListService } from './chirpy-chit-chat-list.service';

@Component({
  selector: 'chirpy-chit-chat-list',
  templateUrl: './chirpy-chit-chat-list.component.html',
  styleUrls: ['./chirpy-chit-chat-list.component.scss']
})
export class ChirpyChitChatListComponent implements OnChanges, OnDestroy {
  get member() {
    return this.service.member;
  }

  get messages() {
    return this.service._messages;
  }

  get messagesToTake() {
    return this.takeTwo === true ? 2 : 1;
  }

  get noMessages() {
    return this.noMessagesText !== '' && this.service._messages.length === 0; // TODO check messages finished loading
  }

  get noMorePastMessages() {
    return this.service.noMorePastMessages;
  }

  get firstMessageDate() {
    return this.service._messages.length > 0 ? this.service._messages[0].dateTimeSent : null;
  }

  @ViewChild('content', { static: false }) content: IonContent;
  @Output() focusInput = new EventEmitter<any>();
  @Input() groupId: string;
  @Input() groupName: string;
  @Input() instructions$: Observable<string>;
  isAdmin: boolean = false;
  @Input() isChatGroup: boolean = false;
  isLoading: boolean = true;
  @Input() isThreadClosed: boolean;
  @Input() messageType: MessageType;
  @Input() noMessagesText: string;
  @Input() takeTwo: boolean; // If there are messages for the same thread rendered on the previous page (e.g. chirpy-last-message component) this prevents stale cached results
  @Input() threadClosedMessage: string;
  threadId: string;

  constructor(private authService: AuthService, private service: ChirpyChitChatListService, private route: ActivatedRoute, private uiService: UIService, private platform: Platform) {}

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.focusInput.emit();
  }

  listenForNewMessages() {
    const dateStart = Date.now();
    const updateUIFunction = this.updateUI.bind(this);
    this.service.listenForNewMessages(updateUIFunction, this.threadId, dateStart);
  }

  loadInitialMessages() {
    const dateStart = Date.now();
    const updateUIFunction = this.updateUI.bind(this);
    this.service.loadInitialMessages(updateUIFunction, this.threadId, dateStart, this.messagesToTake);
  }

  loadPastMessages() {
    this.isLoading = true;
    const updateUIFunction = this.updateUI.bind(this);
    this.service.loadPastMessages(updateUIFunction, this.threadId);
  }

  ngOnDestroy() {
    // Stop listening for new messages, and/or terminate any observables loading past messages
    this.service.stopReceivingMessages$.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    // use onChanges rather than onInit to prevent race condition in parent component causing a null value to be passed for groupId, which never gets updated
    if (changes.groupId && changes.groupId != null) {
      switch (this.messageType) {
        case MessageType.CATCHUP_NOTE:
          this.threadId = `catchup_${this.groupId}`;
          break;

        case MessageType.CHIT_CHAT:
          this.threadId = `group_${this.groupId}`;
          break;

        default:
          break;
      }
      this.isAdmin = this.authService.isAdmin([AdminRole.MODERATOR, AdminRole.SUPER]);
      this.service.stopReceivingMessages$.next();

      // TODO: Is this the best way to handle if threadId is not set?
      if (this.threadId) {
        this.service.resetMessages();
        this.loadInitialMessages();
        this.listenForNewMessages();
      }
    }
  }

  onDelete(messageId: string): void {
    this.service.deleteMessage(messageId);
  }

  onModerate(data: any): void {
    this.service.processMessages([data]);
  }

  sortByKeyDescending(a: any, b: any): number {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  }

  updateUI() {
    this.isLoading = false;
    this.focusInput.emit();
  }
}
