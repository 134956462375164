import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { COLLECTION } from '@shared/constants/collection';
import { CursorType } from '@shared/constants/cursor-type';
import { FirebaseObject } from '@shared/models/firebase-object';
import { IHasUid } from '@shared/models/hasUid';
import { ICursor } from '@shared/models/cursor';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { combineLatest, from, Observable } from 'rxjs';
import { flatMap, mergeMap, map, scan } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class BaseDatabase {
  protected readonly COLLECTION = COLLECTION;
  protected readonly MAX_OPS_PER_BATCH: number = 499; // Firebase supports 500 operations per batch

  async batchDelete(ref: firebase.firestore.DocumentReference, batch: firebase.firestore.WriteBatch, count: number) {
    return await this.batchOperation('delete', ref, batch, count);
  }

  async batchSet(ref: firebase.firestore.DocumentReference, data: any, batch: firebase.firestore.WriteBatch, count: number, extraCount: number = 0) {
    return await this.batchOperation('set', ref, batch, count, extraCount, data);
  }

  async batchUpdate(ref: firebase.firestore.DocumentReference, data: any, batch: firebase.firestore.WriteBatch, count: number, extraCount: number = 0) {
    return await this.batchOperation('update', ref, batch, count, extraCount, data);
  }

  async batchOperation(type: string, ref: firebase.firestore.DocumentReference, batch: firebase.firestore.WriteBatch, count: number, extraCount: number = 0, data: any = {}) {
    let newCount = count + extraCount + 1; // values like FieldValue.increment count as a batch operation, we need to manually account for them
    let newBatch = batch; //compiler/linter doesn't like reusing/reassigning the variable batch within this function
    if (newCount >= this.MAX_OPS_PER_BATCH) {
      await batch.commit();
      newBatch = this.afs.firestore.batch();
      newCount = extraCount + 1;
    }
    switch (type) {
      case 'delete':
        newBatch.delete(ref);
        break;

      case 'set':
        newBatch.set(ref, data, { merge: true });
        break;

      case 'update':
        newBatch.update(ref, data);
        break;

      default:
        break;
    }

    return { batch: newBatch, count: newCount };
  }

  constructor(protected afs: AngularFirestore, protected cache: CacheService) {}

  createDocument(collectionName: string, document: any): Promise<firebase.firestore.DocumentReference> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    return this.afs.collection(collectionName).add({ ...data });
  }

  createId(): string {
    return this.afs.createId();
  }

  createSubDocument(collectionName: string, documentId: string, subCollectionName: string, document: any): Promise<firebase.firestore.DocumentReference> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    return this.afs
      .collection(collectionName)
      .doc(documentId)
      .collection(subCollectionName)
      .add({ ...data });
  }

  createChunkedQueryFunctions(values: string[], field: string, operator: string, whereConditions: IWhereCondition[] = [], limit: boolean = false): any[] {
    const MAX_ITEMS_PER_QUERY = 10; // Firestore in and array-contains-any operator have a limit of 10 values per query
    const MAX_SEARCH_RESULTS = 30; // Reduce database reads by limiting the number of results per search
    const numberOfQueries = Math.ceil(values.length / MAX_ITEMS_PER_QUERY);
    const output = [];
    for (let i = 0; i < numberOfQueries; i++) {
      const lowerOffset = i * MAX_ITEMS_PER_QUERY;
      const upperOffset = (i + 1) * MAX_ITEMS_PER_QUERY;

      const queryFunction = ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        //Add query chunk
        query = ref.where(field, operator, values.slice(lowerOffset, upperOffset));
        //Add other conditions
        whereConditions.forEach(condition => {
          query = query.where(condition.field, condition.operator, condition.value);
        });
        // limit results
        if (limit) query = query.limit(MAX_SEARCH_RESULTS);
        return query;
      };
      output.push(queryFunction);
    }
    return output;
  }

  createQueryFunction(whereConditions: IWhereCondition[], orderConditions: IOrderCondition[], LIMIT_RECORDS: number, cursors: ICursor[] = []) {
    const queryFunction = ref => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;

      whereConditions.forEach(condition => {
        query = query.where(condition.field, condition.operator, condition.value);
      });

      orderConditions.forEach(order => {
        query = query.orderBy(order.field, order.direction);
      });

      if (cursors.length > 0) {
        query = this.getCursors(query, cursors);
      }

      query = query.limit(LIMIT_RECORDS);

      return query;
    };

    return queryFunction;
  }

  deleteDocument(collectionName: string, docId: string) {
    return this.afs
      .collection(collectionName)
      .doc(docId)
      .delete();
  }

  deleteSubDocument(collectionName: string, docId: string, subCollectionName: string, subDocId: string) {
    return this.afs
      .collection(collectionName)
      .doc(docId)
      .collection(subCollectionName)
      .doc(subDocId)
      .delete();
  }

  getDocument<T>(collectionName: string, uid: string, useCache: boolean = false) {
    if (useCache) {
      const cachedDocument = this.cache.getObservable<T>(collectionName, uid);
      if (cachedDocument !== null) return cachedDocument;
    }

    return this.afs
      .collection<T>(collectionName)
      .doc<T>(uid)
      .snapshotChanges()
      .pipe(
        map(action => {
          const document = action.payload.data();
          if (document != null) {
            (document as any).uid = action.payload.id;
            if (useCache) this.cache.update(collectionName, uid, document);
          }
          return document;
        })
      );
  }

  getDocuments<T>(collectionName: string, useCache: boolean = false): Observable<T[]> {
    if (useCache) {
      const cachedDocument = this.cache.getObservable<T[]>(collectionName, 'all');
      if (cachedDocument !== null) return cachedDocument;
    }

    return this.afs
      .collection<T>(collectionName)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const results = actions.map(a => {
            const data = a.payload.doc.data();
            (data as any).uid = a.payload.doc.id;

            return data;
          });
          if (useCache) this.cache.update(collectionName, 'all', results);
          return results;
        })
      );
  }

  getDocumentsByMultipleQueries<T>(collectionName: string, queryFns: Array<(ref) => firebase.firestore.Query>) {
    return from(queryFns).pipe(
      // stateChanges rather than snapshotChanges is necessary so that removed items (e.g. Socials) are no longer shown in the accumulated results
      mergeMap(queryFn => this.afs.collection<T>(collectionName, queryFn).stateChanges()),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          (data as any).uid = a.payload.doc.id;
          return { data: data, type: a.type, uid: a.payload.doc.id };
        });
      }),

      scan((acc, current) => {
        for (let item of current) {
          if (item.type === 'removed') {
            acc = acc.filter(x => x.uid === item.uid);
          } else {
            acc.push(item.data); // TODO: replace existing value rather than appending a duplicate?
          }
        }
        return acc;
      }, [])
    );
  }

  getDocumentsByMultipleQueriesWithSubcollection<T extends IHasUid>(collectionName: string, queryFns: Array<(ref) => firebase.firestore.Query>, subCollectionName: string) {
    return from(queryFns).pipe(
      // stateChanges rather than snapshotChanges is necessary so that removed items (e.g. Socials) are no longer shown in the accumulated results
      mergeMap(queryFn => this.afs.collection<T>(collectionName, queryFn).stateChanges()),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          (data as any).uid = a.payload.doc.id;
          return { data: data, type: a.type, uid: a.payload.doc.id };
        });
      }),
      map(documents =>
        documents.map(document => {
          return this.afs
            .collection(`${collectionName}/${document.uid}/${subCollectionName}`)
            .snapshotChanges()
            .pipe(
              map(actions =>
                actions.map(a => {
                  const data = a.payload.doc.data();
                  (data as any).uid = a.payload.doc.id;

                  return data;
                })
              ),
              map(subdocuments => {
                Object.assign(document.data, { [subCollectionName]: subdocuments });
                return document;
              })
            );
        })
      ),
      flatMap(combined => combineLatest(combined)),
      scan((acc, current) => {
        for (let item of current) {
          if (item.type === 'removed') {
            delete acc[item.uid];
          } else {
            acc[item.uid] = item.data;
          }
        }
        return acc;
      }, {}),
      map((lookupTable: Record<string, T>) => Object.values(lookupTable))
    );
  }

  getDocumentsByCollectionGroupQuery<T>(collectionName: string, queryFn: (ref) => firebase.firestore.Query, parentUidField: string = 'parentUid'): Observable<T[]> {
    return this.afs
      .collectionGroup<T>(collectionName, queryFn)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            (data as any).uid = a.payload.doc.id;
            // We want the top-level parent, i.e. the id of the document that contains the top-level collectionName collection
            //e.g. we want to get messageId from either path = messagesThreads/{threadId}/Messages/{messageId} or messagesThreads/{threadId}/Messages/{messageId}/Messages/{replyId}
            (data as any)[parentUidField] = a.payload.doc.ref.path
              .split(`/${collectionName}`)[0]
              .split('/')
              .pop();

            return data;
          })
        )
      );
  }

  getDocumentsByQuery<T>(collectionName: string, queryFn: (ref) => firebase.firestore.Query, useCache: boolean = false, cacheKey: string = ''): Observable<T[]> {
    if (useCache && cacheKey) {
      const cachedDocument = this.cache.getObservable<T[]>(collectionName, cacheKey);
      if (cachedDocument !== null) return cachedDocument;
    }

    return this.afs
      .collection<T>(collectionName, queryFn)
      .snapshotChanges()
      .pipe(
        map(actions => {
          const results = actions.map(a => {
            const data = a.payload.doc.data();
            (data as any).uid = a.payload.doc.id;

            return data;
          });
          if (useCache) this.cache.update(collectionName, cacheKey, results);
          return results;
        })
      );
  }

  getDocumentsByQueryWithSubcollection<T extends IHasUid>(collectionName: string, queryFn: (ref) => firebase.firestore.Query, subCollectionName: string): Observable<T[]> {
    return this.afs
      .collection<T>(collectionName, queryFn)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            (data as any).uid = a.payload.doc.id;

            return data;
          })
        ),
        map(documents => {
          return documents.map(document => {
            return this.afs
              .collection(`${collectionName}/${document.uid}/${subCollectionName}`)
              .snapshotChanges()
              .pipe(
                map(actions =>
                  actions.map(a => {
                    const data = a.payload.doc.data();
                    (data as any).uid = a.payload.doc.id;

                    return data;
                  })
                ),
                map(subdocuments => Object.assign(document, { [subCollectionName]: subdocuments }))
              );
          });
        }),
        flatMap(combined => combineLatest(combined))
      );
  }

  getDocumentsBySubcollectionQuery<T>(collectionName: string, docId: string, subCollectionName: string, queryFn: (ref) => firebase.firestore.Query): Observable<T[]> {
    return this.afs
      .collection(collectionName)
      .doc(docId)
      .collection<T>(subCollectionName, queryFn)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            (data as any).uid = a.payload.doc.id;
            return data;
          })
        )
      );
  }

  getDocumentValueChanges<T>(collectionName: string, uid: string, useCache: boolean = false) {
    if (useCache) {
      const cachedDocument = this.cache.getObservable<T>(collectionName, uid);
      if (cachedDocument !== null) return cachedDocument;
    }

    return this.afs
      .collection<T>(collectionName)
      .doc<T>(uid)
      .valueChanges()
      .pipe(
        map(document => {
          if (document != null) {
            if (useCache) this.cache.update(collectionName, uid, document);
          }
          return document;
        })
      );
  }

  getSubDocument<T>(collectionName: string, docId: string, subCollectionName: string, subDocId: string) {
    return this.afs
      .collection(collectionName)
      .doc(docId)
      .collection(subCollectionName)
      .doc<T>(subDocId)
      .snapshotChanges()
      .pipe(
        map(action => {
          const document = action.payload.data();
          if (document != null) {
            (document as any).uid = action.payload.id;
          }
          return document;
        })
      );
  }

  // .update will remove unset properties in nested objects, .set({merge:true}) will only update explicitly specified properties
  strictUpdateDocument(collectionName: string, docId: string, document: any): Promise<void> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    return this.afs
      .collection(collectionName)
      .doc(docId)
      .update({ ...data });
  }

  // .update will remove unset properties in nested objects, .set({merge:true}) will only update explicitly specified properties
  strictUpdateSubDocument(collectionName: string, docId: string, subCollectionName: string, subDocId: string, document: any): Promise<void> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    return this.afs
      .collection(collectionName)
      .doc(docId)
      .collection(subCollectionName)
      .doc(subDocId)
      .update({ ...data });
  }

  updateDocument(collectionName: string, docId: string, document: any, merge = true, updateCache = false): Promise<void> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    if (updateCache) {
      let dataToCache = { ...document };

      if (merge) {
        const cached = this.cache.getValue(collectionName, docId);
        dataToCache = { ...cached, ...document};
      }

      this.cache.update(collectionName, docId, dataToCache);
    }

    return this.afs
      .collection(collectionName)
      .doc(docId)
      .set({ ...data }, { merge });
  }

  updateSubDocument(collectionName: string, docId: string, subCollectionName: string, subDocId: string, document: any, merge: boolean = true): Promise<void> {
    const data: any = {};
    Object.assign(data, document);
    delete data.uid;

    return this.afs
      .collection(collectionName)
      .doc(docId)
      .collection(subCollectionName)
      .doc(subDocId)
      .set({ ...data }, { merge });
  }

  private getCursors(query: firebase.firestore.CollectionReference | firebase.firestore.Query, cursors: ICursor[]) {
    cursors.forEach((cursor: ICursor) => {
      switch (cursor.type) {
        case CursorType.END_AT:
          query = query.endAt(cursor.value);
          break;

        case CursorType.END_BEFORE:
          query = query.endBefore(cursor.value);
          break;

        case CursorType.START_AFTER:
          query = query.startAfter(cursor.value);
          break;

        case CursorType.START_AT:
          query = query.startAt(cursor.value);
          break;

        default:
          break;
      }
    });
    return query;
  }
}
