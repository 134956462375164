import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IonicModule } from '@ionic/angular';
import { ChirpyAccordionComponent } from '@shared/components/chirpy-accordion/chirpy-accordion.component';
import { ChirpyAddPhotoComponent } from '@shared/components/chirpy-add-photo/chirpy-add-photo.component';
import { ChirpyAdvertiserComponent } from '@shared/components/chirpy-advertiser/chirpy-advertiser.component';
import { ChirpyAvatarComponent } from '@shared/components/chirpy-avatar/chirpy-avatar.component';
import { ChirpyAvatarListComponent } from '@shared/components/chirpy-avatar-list/chirpy-avatar-list.component';
import { ChirpyBadgesComponent } from '@shared/components/chirpy-badges/chirpy-badges.component';
import { ChirpyBadgeTableComponent } from '@shared/components/chirpy-badge-table/chirpy-badge-table.component';
import { ChirpyButtonComponent } from '@shared/components/chirpy-button/chirpy-button.component';
import { ChirpyCalendarDate } from '@shared/components/chirpy-calendar-date/chirpy-calendar-date.component';
import { ChirpyCatchupListComponent } from '@shared/components/chirpy-catchup-list/chirpy-catchup-list.component';
import { ChirpyCatchupTimeComponent } from '@shared/components/chirpy-catchup-time/chirpy-catchup-time.component';
import { ChirpyChangePasswordComponent } from '@shared/components/chirpy-change-password/chirpy-change-password.component';
import { ChirpyChitChatDetailComponent } from '@shared/components/chirpy-chit-chat-detail/chirpy-chit-chat-detail.component';
import { ChirpyChitChatListComponent } from '@shared/components/chirpy-chit-chat-list/chirpy-chit-chat-list.component';
import { ChirpyChitChatRepliesComponent } from '@shared/components/chirpy-chit-chat-replies/chirpy-chit-chat-replies.component';
import { ChirpyCoinsTransactionsTableComponent } from '@shared/components/chirpy-coins-transactions-table/chirpy-coins-transactions-table.component';
import { ChirpyCoinsBalanceComponent } from '@shared/components/chirpy-coins-balance/chirpy-coins-balance.component';
import { ChirpyCommentDetailComponent } from '@shared/components/chirpy-comment-detail/chirpy-comment-detail.component';
import { ChirpyCommentListComponent } from '@shared/components/chirpy-comment-list/chirpy-comment-list.component';
import { ChirpyContactFormComponent } from '@shared/components/chirpy-contact-form/chirpy-contact-form.component';
import { ChirpyContentEditComponent } from '@shared/components/chirpy-content-edit/chirpy-content-edit.component';
import { ChirpyContentViewComponent } from '@shared/components/chirpy-content-view/chirpy-content-view.component';
import { ChirpyDataTableComponent } from '@shared/components/chirpy-data-table/chirpy-data-table.component';
import { ChirpyDisclaimerComponent } from '@shared/components/chirpy-disclaimer/chirpy-disclaimer.component';
import { ChirpyEarnCoinsTableComponent } from '@shared/components/chirpy-earn-coins-table/chirpy-earn-coins-table.component';
import { ChirpyEditMediaMetadataComponent } from '@shared/components/chirpy-edit-media-metadata/chirpy-edit-media-metadata.component';
import { ChirpyErrorMessageComponent } from '@shared/components/chirpy-error-message/chirpy-error-message.component';
import { ChirpyEventCardsComponent } from '@shared/components/chirpy-event-cards/chirpy-event-cards.component';
import { ChirpyGroupListComponent } from '@shared/components/chirpy-group-list/chirpy-group-list.component';
import { ChirpyGroupListWidgetComponent } from '@shared/components/chirpy-group-list-widget/chirpy-group-list-widget.component';
import { ChirpyImageComponent } from '@shared/components/chirpy-image/chirpy-image.component';
import { ChirpyLinkListComponent } from '@shared/components/chirpy-link-list/chirpy-link-list.component';
import { ChirpyLocatorMapComponent } from '@shared/components/chirpy-locator-map/chirpy-locator-map.component';
import { ChirpyLockedContentComponent } from './components/chirpy-locked-content/chirpy-locked-content';
import { ChirpyLastMessageComponent } from '@shared/components/chirpy-last-message/chirpy-last-message.component';
import { ChirpyMemberListComponent } from '@shared/components/chirpy-member-list/chirpy-member-list.component';
import { ChirpyAddRemoveMemberComponent } from '@shared/components/chirpy-member-search/add-remove-member/add-remove-member.component';
import { ChirpyMemberSearchComponent } from '@shared/components/chirpy-member-search/chirpy-member-search.component';
import { ChirpyRemoveMemberComponent } from '@shared/components/chirpy-member-search/remove-member/remove-member.component';
import { ChirpyMessageDetailComponent } from '@shared/components/chirpy-message-detail/chirpy-message-detail.component';
import { ChirpyMessageListComponent } from '@shared/components/chirpy-message-list/chirpy-message-list.component';
import { ChirpyMessageSeenByComponent } from '@shared/components/chirpy-message-seen-by/chirpy-message-seen-by.component';
import { ChirpyPageHeaderComponent } from '@shared/components/chirpy-page-header/chirpy-page-header.component';
import { ChirpyParagraphWithMediaComponent } from '@shared/components/chirpy-paragraph-with-media/chirpy-paragraph-with-media.component';
import { ChirpyPopoverButtonsComponent } from '@shared/components/chirpy-popover-buttons/chirpy-popover-buttons.component';
import { ChirpyPopoverListComponent } from '@shared/components/chirpy-popover-list/chirpy-popover-list.component';
import { ChirpyRedeemCoinsFormComponent } from '@shared/components/chirpy-redeem-coins-form/chirpy-redeem-coins-form.component';
import { ChirpyRedeemCoinsTableComponent } from '@shared/components/chirpy-redeem-coins-table/chirpy-redeem-coins-table.component';
import { ChirpyPhotoDetailComponent } from '@shared/components/chirpy-photo-detail/chirpy-photo-detail.component';
import { ChirpyPhotoListComponent } from '@shared/components/chirpy-photo-list/chirpy-photo-list.component';
import { ChirpyPhotoUploadComponent } from '@shared/components/chirpy-photo-upload/chirpy-photo-upload.component';
import { ChirpySelectItemsComponent } from '@shared/components/chirpy-select-items/chirpy-select-items.component';
import { ChirpyItemSearchComponent } from '@shared/components/chirpy-select-items/chirpy-item-search/chirpy-item-search.component';
import { ChirpyPlaceSearchComponent } from '@shared/components/chirpy-select-items/chirpy-place-search/chirpy-place-search.component';
import { ChirpyAddRemoveItemComponent } from '@shared/components/chirpy-select-items/components/add-remove-item/add-remove-item.component';
import { ChirpyRemoveItemComponent } from '@shared/components/chirpy-select-items/components/remove-item/remove-item.component';
import { ChirpySendMessageButtonComponent } from '@shared/components/chirpy-send-message-button/chirpy-send-message-button.component';
import { ChirpySendMessageComponent } from '@shared/components/chirpy-send-message/chirpy-send-message.component';
import { ChirpySocialListComponent } from '@shared/components/chirpy-social-list/chirpy-social-list.component';
import { ChirpyTakePhotoComponent } from '@shared/components/chirpy-take-photo/chirpy-take-photo.component';
import { ChirpyTextAvatarComponent } from '@shared/components/chirpy-text-avatar/chirpy-text-avatar.component';
import { ChirpyVirtualCatchupComponent } from '@shared/components/chirpy-virtual-catchup/chirpy-virtual-catchup.component';
import { ChirpyHrefToRouterLinkDirective } from '@shared/directives/chirpy-href-to-router-link.directive';
import { ChirpyIonTextAreaDirective } from '@shared/directives/chirpy-ion-text-area.directive';
import { ChirpyResizeObserverDirective } from '@shared/directives/chirpy-resize-observer.directive';
import { CallbackPipe } from '@shared/filters/callback.pipe';
import { LocationPipe } from '@shared/filters/location.pipe';
import { LinkifyPipe } from '@shared/filters/linkify.pipe';
import { Nl2BrPipe } from '@shared/filters/nl2br.pipe';
import { ParagraphifyPipe } from '@shared/filters/paragraphify.pipe';
import { SafePipe } from '@shared/filters/safe.pipe';
import { UidPipe } from '@shared/filters/uid.pipe';
import { BeaconService } from '@shared/services/beacon.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { OrderModule } from 'ngx-order-pipe';
import { WebcamModule } from 'ngx-webcam';

const CommonModules = [CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule, OrderModule];
const SharedComponents = [
  ChirpyAccordionComponent,
  ChirpyAddPhotoComponent,
  ChirpyAdvertiserComponent,
  ChirpyAvatarComponent,
  ChirpyAvatarListComponent,
  ChirpyBadgesComponent,
  ChirpyBadgeTableComponent,
  ChirpyButtonComponent,
  ChirpyCalendarDate,
  ChirpyCatchupListComponent,
  ChirpyCatchupTimeComponent,
  ChirpyChitChatDetailComponent,
  ChirpyChitChatListComponent,
  ChirpyChitChatRepliesComponent,
  ChirpyCoinsBalanceComponent,
  ChirpyCoinsTransactionsTableComponent,
  ChirpyCommentDetailComponent,
  ChirpyCommentListComponent,
  ChirpyContactFormComponent,
  ChirpyContentEditComponent,
  ChirpyContentViewComponent,
  ChirpyChangePasswordComponent,
  ChirpyDataTableComponent,
  ChirpyDisclaimerComponent,
  ChirpyEarnCoinsTableComponent,
  ChirpyEditMediaMetadataComponent,
  ChirpyErrorMessageComponent,
  ChirpyEventCardsComponent,
  ChirpyGroupListComponent,
  ChirpyGroupListWidgetComponent,
  ChirpyLinkListComponent,
  ChirpyLocatorMapComponent,
  ChirpyLockedContentComponent,
  ChirpyLastMessageComponent,
  ChirpyAddRemoveMemberComponent,
  ChirpyMemberListComponent,
  ChirpyMemberSearchComponent,
  ChirpyRemoveMemberComponent,
  ChirpyMessageDetailComponent,
  ChirpyMessageListComponent,
  ChirpyMessageSeenByComponent,
  ChirpyPageHeaderComponent,
  ChirpyParagraphWithMediaComponent,
  ChirpyPopoverButtonsComponent,
  ChirpyPopoverListComponent,
  ChirpyRedeemCoinsFormComponent,
  ChirpyRedeemCoinsTableComponent,
  ChirpyPlaceSearchComponent,
  ChirpyPhotoDetailComponent,
  ChirpyPhotoListComponent,
  ChirpyPhotoUploadComponent,
  ChirpyImageComponent,
  ChirpySendMessageComponent,
  ChirpySendMessageButtonComponent,
  ChirpySocialListComponent,
  ChirpyTakePhotoComponent,
  ChirpyTextAvatarComponent,
  ChirpyVirtualCatchupComponent,
  ChirpyAddRemoveItemComponent,
  ChirpyRemoveItemComponent,
  ChirpyItemSearchComponent,
  ChirpySelectItemsComponent
];
const SharedFilters = [CallbackPipe, LocationPipe, LinkifyPipe, Nl2BrPipe, ParagraphifyPipe, SafePipe, UidPipe];
const SharedServices = [BeaconService, DateTimeService];
const SharedDirectives = [ChirpyHrefToRouterLinkDirective, ChirpyIonTextAreaDirective, ChirpyResizeObserverDirective];

@NgModule({
  declarations: [...SharedFilters, ...SharedComponents, ...SharedDirectives],
  imports: [...CommonModules, LeafletModule, WebcamModule, IonicModule, RouterModule],
  exports: [...CommonModules, IonicModule, ...SharedFilters, ...SharedComponents, ...SharedDirectives],
  providers: [...SharedServices],
  entryComponents: [ChirpyItemSearchComponent, ChirpyPlaceSearchComponent, ChirpyPopoverListComponent, ChirpyTakePhotoComponent],
  bootstrap: []
})
export class SharedModule {}
