// these collections exist in Firebase
export const COLLECTION = {
  ADVERTISERS: 'advertisers',
  ANNOUNCEMENTS: 'Announcements', // sub-collection of advertisers
  APP: 'app',
  APP_OPTIONS: 'appOptions',
  ATTENDANCE: 'attendance',
  CANCELLATIONS: 'cancellations',
  BNB_LISTINGS: 'bnbListings',
  CATCHUP_GROUPS: 'catchupGroups',
  CATCHUPS: 'catchups',
  CATCHUP_TEMPLATES: 'CatchupTemplates', // sub-collection of catchupGroups
  CENTRAL_MEMBERS: 'centralMembers',
  CENTRAL_MEMBERS_PASSWORD: 'centralMembersPassword',
  CENTRAL_MEMBERS_PRIVATE: 'centralMembersPrivate',
  CENTRAL_PLACES: 'centralPlaces',
  CENTRAL_PLACES_INDEX: 'centralPlacesIndex',
  COINS_EXPIRY: 'coinsExpiry',
  COINS_MEMBERS_TRANSACTIONS: 'coinsMembersTransactions',
  COINS_TRANSACTIONS: 'coinsTransactions',
  COMMENTS: 'Comments', // sub-collection of photos,...
  CONTENT_PAGES: 'contentPages',
  EVENTS: 'events',
  GROUP_NAMES: 'groupNames',
  HELD_MESSAGES: 'heldMessages',
  HOST_ONLY: 'HostOnly', // sub-collection of CatchUps
  LIMITS: 'limits',
  MAIL: 'mail',
  MAIL_TEMPLATES: 'mailTemplates',
  MARKETPLACE_LISTINGS: 'marketplaceListings',
  MATCHES: 'Matches', // sub-collection of trips
  MEDIA: 'media',
  MEDIA_COLLECTIONS: 'mediaCollections',
  MESSAGES: 'Messages', // sub-collection of messagesThreads
  MESSAGES_MEMBER_THREADS: 'messagesMemberThreads',
  MESSAGES_THREADS: 'messagesThreads',
  NEWS: 'news',
  NOTICES: 'Notices', // sub-collection of catchupGroups
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_SETTINGS: 'notificationSettings',
  REVIEWS: 'Reviews', // sub-collection of BnBListings
  SHARE_LISTINGS: 'shareListings',
  ROOM_SHARE: 'RoomShare', // sub-collection of trips
  SOCIALS: 'socials',
  TARGETS: 'Targets', // sub-collection of notifications
  TRIPS: 'trips'
};
