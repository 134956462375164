import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { Observable } from 'rxjs';
import { IBingoGame } from '../models/bingo-game';
import { GameStatus } from '../models/game-status';

@Injectable({
  providedIn: 'root'
})
export class BingoDatabase extends BaseDatabase {
  private readonly COLLECTION_PATH = '/games/bingo/games'; // Safe to add this to shared COLLECTION constant?

  constructor(afs: AngularFirestore, cache: CacheService) {
    super(afs, cache);
  }

  async createGame(data: Omit<IBingoGame, 'uid'>): Promise<IBingoGame> {
    const newGame = await this.createDocument(this.COLLECTION_PATH, data);

    return {
      ...data,
      uid: newGame.id
    };
  }

  getAllGames(userId: string, recordsToFetch?: number): Observable<IBingoGame[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'userId',
        operator: '==',
        value: userId
      },
      {
        field: 'version',
        operator: '==',
        value: 2
      }
    ];

    const orderConditions: IOrderCondition[] = [
      {
        field: 'createdAt',
        direction: 'desc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery(this.COLLECTION_PATH, queryFn);
  }

  getGame(gameId: string, useCache = false): Observable<IBingoGame> {
    return this.getDocument<IBingoGame>(this.COLLECTION_PATH, gameId, useCache);
  }

  getGamesWithStatus(userId: string, status: GameStatus, recordsToFetch?: number): Observable<IBingoGame[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: 'userId',
        operator: '==',
        value: userId
      },
      {
        field: 'version',
        operator: '==',
        value: 2
      },
      {
        field: 'status',
        operator: '==',
        value: status
      }
    ];

    const orderConditions: IOrderCondition[] = [
      {
        field: 'createdAt',
        direction: 'asc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery(this.COLLECTION_PATH, queryFn);
  }

  updateGame(gameId: string, data: unknown, merge = true) {
    const updateCache = true;

    const document = {
      ...data,
      uid: gameId,
      updatedAt: new Date().getTime()
    };

    return this.updateDocument(this.COLLECTION_PATH, gameId, document, merge, updateCache);
  }
}
