import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { IBingoGame } from '../../../models/bingo-game';
import { IBingoGameSettings } from '../../../models/bingo-settings';
import { BingoGameSpeedOption } from '../../../models/game-speed-option';
import { IPauseMenuDismissedAction } from '../../models/pause-menu-dismissed-event';

@Component({
  selector: 'chirpy-bingo-pause-menu',
  styleUrls: ['./pause-menu.component.scss'],
  templateUrl: './pause-menu.component.html'
})
export class BingoPauseMenuComponent implements OnInit {
  gameSpeed: number;
  gameSpeedOptions: number[] = Object.values(BingoGameSpeedOption);
  isDirty = false;
  settings: IBingoGameSettings = { delayInMs: BingoGameSpeedOption.MEDIUM };

  @Input() game: Pick<IBingoGame, 'uid' | 'settings'>;

  constructor(private analyticsService: AnalyticsService, private modalController: ModalController) {}

  ngOnInit() {
    if (this.game && this.game.settings) {
      this.settings = { ...this.game.settings };
    }

    this.gameSpeed = Math.max(
      this.gameSpeedOptions.findIndex(opt => opt === this.settings.delayInMs),
      0
    );
  }

  onGameSpeedChanged(e: CustomEvent) {
    if (!('value' in e.detail)) {
      return;
    }

    const index = Number(e.detail.value);
    const gameSpeed = this.gameSpeedOptions[index];

    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_CHANGE_SETTINGS, this.game.uid, null, gameSpeed);

    if (gameSpeed) {
      this.isDirty = true;
      this.settings.delayInMs = gameSpeed;
    }
  }

  onRestartClicked() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESTART_GAME, this.game.uid);
    this.onActionButtonClicked('restart');
  }

  onResumeClicked() {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESUME_GAME, this.game.uid);
    this.onActionButtonClicked('resume');
  }

  private onActionButtonClicked(type: IPauseMenuDismissedAction['type']) {
    const action: IPauseMenuDismissedAction = {
      type,
      data: {
        dirty: this.isDirty,
        updatedSettings: this.settings
      }
    };

    this.modalController.dismiss(action);
  }
}
