import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBingoGame } from '../../../models/bingo-game';

@Component({
  selector: 'bingo-play-game',
  templateUrl: './play-game.component.html'
})
export class BingoPlayGameComponent {
  @Input() gameInProgress: IBingoGame;

  @Output() resumeGameEvent = new EventEmitter<string>();
  @Output() startNewGameEvent = new EventEmitter<void>();

  onStartNewGame() {
    this.startNewGameEvent.emit();
  }

  onResumeGame(gameId: string) {
    this.resumeGameEvent.emit(gameId);
  }
}
