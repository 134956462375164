import { Injectable } from '@angular/core';
import { BadgeType } from '@shared/constants/badge-type';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ICoinsExpiry } from '@shared/models/coins/coins-expiry';
import { ICoinsLevel } from '@shared/models/coins/coins-level';
import { ICoinsOptions } from '@shared/models/coins/coins-options';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { ICentralMemberPrivate } from '@shared/models/central-member';
import { CoinsDatabase } from '@shared/services/coins/coins.database';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoinsService {
  get LEVELS(): ICoinsLevel[] {
    return this.constantsService.constants.COINS.levels || [];
  }

  get MAX_LEVEL(): ICoinsLevel {
    return this.LEVELS.slice(-1).pop();
  }

  get MIN_LEVEL(): ICoinsLevel {
    return this.LEVELS.slice(0, 1).pop();
  }

  constructor(private coinsDatabase: CoinsDatabase, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private userDatabase: UserDatabase) {}

  addCoinsTransaction(transaction: ICoinsTransaction): Promise<any> {
    switch (transaction.type) {
      case CoinsTransactionType.REDEEM:
        const today = this.dateTimeService.getStartOfToday();
        return this.coinsDatabase.addCoinsRedeemedTransaction(transaction, today);

      case CoinsTransactionType.ADD:
      default:
        const expiry: ICoinsExpiry = {
          startDate: this.dateTimeService.getDateTime(),
          deleteDate: this.dateTimeService.addYearsToCurrentDate(3),
          startAmount: transaction.amount,
          currentAmount: transaction.amount,
          memberId: transaction.memberId,
          transactionIds: []
        };
        return this.coinsDatabase
          .addCoinsEarnedTransaction(transaction, expiry)
          .then(result =>
            this.userDatabase
              .getPrivateMemberData(transaction.memberId)
              .pipe(
                skipWhile(u => !u),
                take(1)
              )
              .toPromise()
          )
          .then(member => this.maybeChangeLevel(member));
    }
  }

  addMemberInitiatedTransaction(transaction: ICoinsTransaction): Promise<any> {
    return this.coinsDatabase.addMemberTransaction(transaction);
  }

  getBalanceForMember(uid: string): Observable<number> {
    return this.coinsDatabase.getBalanceForMember(uid);
  }

  getLevelByName(levelName: string): ICoinsLevel {
    return this.LEVELS.find(x => x.name === levelName) || this.MIN_LEVEL;
  }

  getLevelByNumber(index: number): ICoinsLevel | null {
    const level = this.LEVELS.slice(index, index + 1); // Use slice rather than LEVELS[index] to prevent out of range errors
    return level[0] || null;
  }

  getCoinsToNextLevel(member: ICentralMemberPrivate, currentLevel: ICoinsLevel, nextLevel: ICoinsLevel) {
    const currentPoints = member.statusPoints || 0;
    return Math.max(this.getNextThreshold(currentLevel, nextLevel) - currentPoints, 0); // Don't show negative value
  }

  getNextLevel(level: ICoinsLevel) {
    return this.getLevelByNumber(level.order + 1) || level;
  }

  getNextThreshold(currentLevel: ICoinsLevel, nextLevel: ICoinsLevel) {
    return currentLevel.name !== nextLevel.name ? nextLevel.threshold - currentLevel.threshold : currentLevel.threshold;
  }

  getPercentageToNextLevel(member: ICentralMemberPrivate, currentLevel: ICoinsLevel, nextLevel: ICoinsLevel) {
    const coinsToNextLevel = this.getCoinsToNextLevel(member, currentLevel, nextLevel);
    return member.statusPoints / (member.statusPoints + coinsToNextLevel); // TODO: Replace denominator with this.getNextThreshold(currentLevel, nextLevel)?
  }

  getPrevLevel(level: ICoinsLevel) {
    return this.getLevelByNumber(level.order - 1) || level;
  }

  getTransactions(search: ICoinsOptions, lastTimestamp: number) {
    const limit = 50;
    return this.coinsDatabase.getTransactions(search, limit, lastTimestamp);
  }

  getTransactionsForMember(uid: string, balance: number, records: number = null, isAdmin: boolean = false): Observable<ICoinsTransaction[]> {
    return this.coinsDatabase.getTransactionsForMember(uid, records, isAdmin).pipe(
      map(transactions => {
        let lastBalance = null;
        // transactions are ordered by date descending, iterate to calculate previous balances
        for (let transaction of transactions) {
          if (lastBalance == null) {
            lastBalance = balance;
          }
          transaction.balance = lastBalance;
          lastBalance = lastBalance - transaction.amount;
        }
        return transactions;
      })
    );
  }

  maybeChangeLevel(member: ICentralMemberPrivate): Promise<void> {
    // Scenarios:
    // 1. Member has -ve statusPoints, already at lowest level, do nothing
    // 2. Member has -ve statusPoints, needs to move down level
    // 3. Member doesn't have enough statusPoints to move to a higher level, do nothing
    // 4. Member has enough statusPoints to move to a higher level
    // 5. Member is already at the highest level, do nothing

    // Scenario 1 and 5
    if (member.statusPoints < 0 && member.coinsLevel === this.MIN_LEVEL.name) return Promise.resolve();
    if (member.statusPoints >= 0 && member.coinsLevel === this.MAX_LEVEL.name) return Promise.resolve();

    // Member can move up/down by more than one level, iterate to find correct one
    const level = this.getLevelByName(member.coinsLevel);
    const totalStatusPoints = level.threshold + member.statusPoints;
    let newLevel = this.MIN_LEVEL;
    for (let l of this.LEVELS) {
      if (totalStatusPoints - l.threshold >= 0) newLevel = l; // Needs to be >= for edge case of member reaching threshold exactly right before statusDate
    }

    // Scenario 3
    if (level.name === newLevel.name) return Promise.resolve();

    // Scenarios 2 and 4
    const data: any = {
      coinsLevel: newLevel.name,
      statusPoints: totalStatusPoints - newLevel.threshold
    };
    // Only reset statusDate if moving up a level
    if (member.statusPoints >= 0) data.statusDate = this.dateTimeService.addYearsToCurrentDate(3);

    return this.coinsDatabase.updateLevel(member.uid, data).then(() => this.updateStatusBadges(member.uid, newLevel));
  }

  reverseTransaction(reversedTransaction: ICoinsTransaction, reversingTransaction: ICoinsTransaction): Promise<any> {
    const updatedData = { type: CoinsTransactionType.REVERSED };
    switch (reversedTransaction.type) {
      case CoinsTransactionType.REDEEM:
        const expiry: ICoinsExpiry = {
          startDate: this.dateTimeService.getDateTime(),
          deleteDate: this.dateTimeService.addYearsToCurrentDate(3),
          startAmount: -reversedTransaction.amount,
          currentAmount: -reversedTransaction.amount,
          memberId: reversedTransaction.memberId,
          transactionIds: []
        };
        return this.coinsDatabase.reverseCoinsRedeemedTransaction(reversedTransaction.uid, updatedData, reversingTransaction, expiry);

      case CoinsTransactionType.ADD:
      default:
        return this.coinsDatabase
          .reverseCoinsEarnedTransaction(reversedTransaction.uid, updatedData, reversingTransaction)
          .then(result =>
            this.userDatabase
              .getPrivateMemberData(reversedTransaction.memberId)
              .pipe(
                skipWhile(u => !u),
                take(1)
              )
              .toPromise()
          )
          .then(member => this.maybeChangeLevel(member));
    }
  }

  updateStatusBadges(memberId: string, newLevel: ICoinsLevel) {
    if (newLevel == null) return;

    // Options are:
    // Show only badge for current status (showLevelsFrom = -1)
    // Show all status badges starting at showLevelsFrom (e.g. can skip lowest level which everyone has)
    const showLevelsFrom: number = this.constantsService.constants.COINS.showLevelsFrom;
    const lowestLevel: number = showLevelsFrom === -1 ? newLevel.order : showLevelsFrom;

    const data = this.LEVELS.reduce((data, x) => {
      data[`level-${x.order}`] = x.order >= lowestLevel && x.order <= newLevel.order ? true : false;
      return data;
    }, {});
    this.userDatabase.updatePublicMemberData(memberId, { badges: { [BadgeType.STATUS]: data } });
  }
}
