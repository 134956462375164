import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ICoinsExpiry } from '@shared/models/coins/coins-expiry';
import { ICoinsOptions } from '@shared/models/coins/coins-options';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { ICentralMemberPrivate } from '@shared/models/central-member';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class CoinsDatabase extends BaseDatabase {
  RECORDS_TO_FETCH: number = 9999;

  async addCoinsEarnedTransaction(transaction: ICoinsTransaction, expiry: ICoinsExpiry) {
    let batch = this.afs.firestore.batch();
    let count = 0;
    const extraCount = 2; // for FieldValue.increment x2

    const transactionId = this.afs.createId();
    const newTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(transactionId).ref;
    ({ batch, count } = await this.batchSet(newTransactionRef, transaction, batch, count));

    expiry.transactionIds = [transactionId];
    const newExpiryRef = this.afs.collection(this.COLLECTION.COINS_EXPIRY).doc(this.afs.createId()).ref;
    ({ batch, count } = await this.batchSet(newExpiryRef, expiry, batch, count));

    const profileRef = this.afs.collection(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE).doc(transaction.memberId).ref;
    ({ batch, count } = await this.batchSet(
      profileRef,
      {
        coinsBalance: FieldValue.increment(transaction.amount),
        statusPoints: FieldValue.increment(transaction.amount)
      },
      batch,
      count,
      extraCount
    ));

    return batch.commit();
  }

  addCoinsRedeemedTransaction(transaction: ICoinsTransaction, today: number): Promise<any> {
    const queryFn = this.getMemberExpiryQuery(transaction.memberId, today);
    // Using this.getDocumentsByQuery doesn't seems to convert to promise
    return this.afs
      .collection(this.COLLECTION.COINS_EXPIRY, queryFn)
      .get()
      .toPromise()
      .then(async response => {
        let total = -transaction.amount;
        const coinsToExpire: any[] = [];
        for (let doc of response.docs) {
          const amount = doc.get('currentAmount');
          if (amount < total) {
            coinsToExpire.push({
              uid: doc.id,
              currentAmount: FieldValue.increment(-amount),
              deleteDate: 0
            });
            total -= amount;
          } else {
            const data: any = {
              uid: doc.id,
              currentAmount: FieldValue.increment(-total)
            };
            if (total === amount) data.deleteDate = 0;
            coinsToExpire.push(data);
            total = 0;
            break;
          }
        }
        if (total !== 0) {
          throw new Error(`Insufficient coins to redeem (need ${total} more)`);
          return;
        }

        let batch = this.afs.firestore.batch();
        let count = 0;

        const transactionId = this.afs.createId();
        const extraCount = 1; //for FieldValue.increment
        const newTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(transactionId).ref;
        ({ batch, count } = await this.batchSet(newTransactionRef, transaction, batch, count));

        for (let expiry of coinsToExpire) {
          const uid = expiry.uid;
          const expiryRef = this.afs.collection(this.COLLECTION.COINS_EXPIRY).doc(uid).ref;
          delete expiry.uid;
          expiry.transactionIds = FieldValue.arrayUnion(transactionId);
          ({ batch, count } = await this.batchSet(expiryRef, expiry, batch, count, extraCount));
        }

        const profileRef = this.afs.collection(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE).doc(transaction.memberId).ref;
        ({ batch, count } = await this.batchSet(profileRef, { coinsBalance: FieldValue.increment(transaction.amount) }, batch, count, extraCount));

        return batch.commit();
      })
      .catch(err => {
        throw err;
      });
  }

  addMemberTransaction(transaction: ICoinsTransaction) {
    return this.createDocument(this.COLLECTION.COINS_MEMBERS_TRANSACTIONS, transaction);
  }

  getBalanceForMember(uid: string): Observable<number> {
    return this.getDocument<ICentralMemberPrivate>(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, uid).pipe(map(privateData => privateData.coinsBalance || 0));
  }

  getTransactions(search: ICoinsOptions, limit: number, lastTimestamp: number): Observable<ICoinsTransaction[]> {
    if (search.amount != null) search.amount = +search.amount; // Enforce numeric type. Dynamically generated form fields can return string value
    const queryFn = this.getTransactionsQuery(search, limit, lastTimestamp);
    return this.getDocumentsByQuery<any>(this.COLLECTION.COINS_TRANSACTIONS, queryFn);
  }

  getTransactionsForMember(uid: string, records: number, isAdmin: boolean): Observable<ICoinsTransaction[]> {
    const queryFn = this.getMemberTransactionsQuery(uid, records, isAdmin);
    return this.getDocumentsByQuery<ICoinsTransaction>(this.COLLECTION.COINS_TRANSACTIONS, queryFn);
  }

  reverseCoinsEarnedTransaction(transactionId: string, updatedData: any, reversalTransaction: ICoinsTransaction) {
    const queryFn = this.getTransactionExpiryQuery(transactionId);
    // Using this.getDocumentsByQuery doesn't seems to convert to promise
    return this.afs
      .collection(this.COLLECTION.COINS_EXPIRY, queryFn)
      .get()
      .toPromise()
      .then(async response => {
        let batch = this.afs.firestore.batch();
        let count = 0;

        const reversalId = this.afs.createId();
        const newTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(reversalId).ref;
        ({ batch, count } = await this.batchSet(newTransactionRef, reversalTransaction, batch, count));

        const oldTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(transactionId).ref;
        ({ batch, count } = await this.batchSet(oldTransactionRef, updatedData, batch, count));

        const extraCount = 2; // for two FieldValue fields
        if (response.docs.length === 1) {
          // Usual scenario
          const doc = response.docs[0];
          const data = doc.data();
          const expiryRef = this.afs.collection(this.COLLECTION.COINS_EXPIRY).doc(doc.id).ref;

          if (data.currentAmount === data.startAmount) {
            // CoinsExpiry is unmodified, just delete it
            ({ batch, count } = await this.batchDelete(expiryRef, batch, count));
          } else {
            // Coins are partially redeemed, subtract original amount, which will produce negative balance
            const expiry = {
              currentAmount: FieldValue.increment(-data.startAmount),
              transactionIds: FieldValue.arrayUnion(reversalId)
            };
            ({ batch, count } = await this.batchSet(expiryRef, expiry, batch, count, extraCount));
          }
        } else {
          // Either no expiry matching this transaction, perhaps already redeemed, do nothing
          // Multiple coinsExpiry associated with one coins earning transaction.
          // Should not be possible, so do nothing
        }

        const profileRef = this.afs.collection(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE).doc(reversalTransaction.memberId).ref;
        ({ batch, count } = await this.batchSet(
          profileRef,
          {
            coinsBalance: FieldValue.increment(reversalTransaction.amount),
            statusPoints: FieldValue.increment(reversalTransaction.amount)
          },
          batch,
          count,
          extraCount
        ));

        return batch.commit();
      })
      .catch(err => {
        throw err;
      });
  }

  async reverseCoinsRedeemedTransaction(transactionId: string, updatedData: any, reversalTransaction: ICoinsTransaction, expiry: any) {
    let batch = this.afs.firestore.batch();
    let count = 0;
    const extraCount = 1; // one FieldValue field

    // Record reversal of transaction
    const reversalId = this.afs.createId();
    const newTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(reversalId).ref;
    ({ batch, count } = await this.batchSet(newTransactionRef, reversalTransaction, batch, count));

    // Update existing transaction record to show it reversed
    const oldTransactionRef = this.afs.collection(this.COLLECTION.COINS_TRANSACTIONS).doc(transactionId).ref;
    ({ batch, count } = await this.batchSet(oldTransactionRef, updatedData, batch, count));

    // Create new expiry record equal to value reversed
    const expiryRef = this.afs.collection(this.COLLECTION.COINS_EXPIRY).doc(this.afs.createId()).ref;
    expiry.transactionIds = FieldValue.arrayUnion(reversalId);
    ({ batch, count } = await this.batchSet(expiryRef, expiry, batch, count, extraCount));

    // Update member balance
    const profileRef = this.afs.collection(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE).doc(reversalTransaction.memberId).ref;
    ({ batch, count } = await this.batchSet(profileRef, { coinsBalance: FieldValue.increment(reversalTransaction.amount) }, batch, count, extraCount));

    return batch.commit();
  }

  updateLevel(memberId: string, data: any): Promise<void> {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, memberId, data);
  }

  private getMemberExpiryQuery(uid: string, today: number) {
    const whereConditions: IWhereCondition[] = [
      { field: 'memberId', operator: '==', value: uid },
      { field: 'deleteDate', operator: '>=', value: today }
    ];
    const orderConditions: IOrderCondition[] = [{ field: 'deleteDate', direction: 'asc' }];

    return this.createQueryFunction(whereConditions, orderConditions, this.RECORDS_TO_FETCH);
  }

  private getMemberTransactionsQuery(uid: string, records: number, isAdmin: boolean) {
    records = records ? records : this.RECORDS_TO_FETCH;
    const whereConditions: IWhereCondition[] = [{ field: 'memberId', operator: '==', value: uid }];
    if (!isAdmin) whereConditions.push({ field: 'type', operator: 'in', value: [CoinsTransactionType.ADD, CoinsTransactionType.REDEEM] });
    const orderConditions: IOrderCondition[] = [{ field: 'date', direction: 'desc' }];

    return this.createQueryFunction(whereConditions, orderConditions, records);
  }

  private getTransactionsQuery(search: ICoinsOptions, recordsToFetch: number, lastTimestamp: number) {
    const whereConditions: IWhereCondition[] = [];

    // If we are loading more entries for the same search
    if (lastTimestamp != null) {
      // If we have already loaded results for the current search, then we always want results < lastTimestamp,
      // regardless of the search, because the results are ordered by startTime desc.
      whereConditions.push({ field: 'date', operator: '<', value: lastTimestamp });

      // TODO: Edge case - what if lastTimestamp is exactly equal to search.dateBefore?
      // Low probability because lastTimstamp has nanosecond precision while search.sentAfter only specifies seconds
    } else {
      if (search.dateBefore !== '') {
        const dateBefore = new Date(search.dateBefore).getTime();
        whereConditions.push({ field: 'date', operator: '<', value: dateBefore });
      }
    }

    if (search.dateAfter !== '') {
      const dateAfter = new Date(search.dateAfter).getTime();
      whereConditions.push({ field: 'date', operator: '>=', value: dateAfter });
    }

    if (search.amount !== null) {
      whereConditions.push({ field: 'amount', operator: '==', value: search.amount });
    }

    if (search.member !== '') {
      whereConditions.push({ field: 'memberId', operator: '==', value: search.member });
    }

    if (search.transactionType !== '') {
      whereConditions.push({ field: 'type', operator: '==', value: search.transactionType });
    }

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'date', direction: 'desc' });
    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }

  private getTransactionExpiryQuery(uid: string) {
    const whereConditions: IWhereCondition[] = [{ field: 'transactionIds', operator: 'array-contains', value: uid }];
    const orderConditions: IOrderCondition[] = [];

    return this.createQueryFunction(whereConditions, orderConditions, this.RECORDS_TO_FETCH);
  }
}
