import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Role } from '@infrastructure/constants/role';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { GroupType } from '@shared/constants/group-type';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IGroup } from '@shared/models/groups/group';
import { ISocial } from '@shared/models/social/social';
import { INotice } from '@shared/models/groups/notice';
import { IOrderCondition } from '@shared/models/order-condition';
import { IValueWithId } from '@shared/models/value-with-id';
import { UserObject } from '@shared/models/user-object';
import { IWhereCondition } from '@shared/models/where-condition';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { BaseDatabase } from '@shared/services/base.database';
import { UserDatabase } from '@shared/services/user/user.database';
import { CacheService } from '@shared/services/cache.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import firebase from 'firebase/app';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupDatabase extends BaseDatabase implements OnDestroy {
  memberSearchSubscription: Subscription;
  MAX_RECORDS: number = 9999;

  constructor(afs: AngularFirestore, cache: CacheService, private authService: AuthService, private constantsService: ConstantsService, private subscriptionService: SubscriptionService, private userDatabase: UserDatabase) {
    super(afs, cache);
  }

  createGroup(group) {
    return this.createDocument(this.COLLECTION.CATCHUP_GROUPS, group);
  }

  createNotice(groupId: string, notice: INotice) {
    return this.createSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.NOTICES, notice);
  }

  deleteGroup(uid: string) {
    return this.deleteDocument(this.COLLECTION.CATCHUP_GROUPS, uid).then(result => {
      this.deleteGroupName(uid);
    });
  }

  deleteGroupName(uid: string) {
    // TODO: Would it be better to use FieldValue.ArrayRemove, rather than reading then filtering?
    // Though if the worng name was used, ArrayRemove wouldn't work, whereas this does.
    const useCache = false;
    this.getDocument<Record<string, IValueWithId[]>>(this.COLLECTION.GROUP_NAMES, 'groups', useCache)
      .pipe(first(x => !!x))
      .subscribe(groups => {
        const data = (groups.ALL || []).filter(x => x.uid !== uid);
        this.updateDocument(this.COLLECTION.GROUP_NAMES, 'groups', { ALL: data }).then(result => {
          this.cache.clear(this.COLLECTION.GROUP_NAMES);
        });
      });
  }

  deleteNotice(groupId: string, noticeId: string) {
    return this.deleteSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.NOTICES, noticeId);
  }

  getAllGroupNames(useCache: boolean = false): Observable<Record<string, IValueWithId[]>> {
    return this.getDocument<Record<string, IValueWithId[]>>(this.COLLECTION.GROUP_NAMES, 'groups', useCache);
  }

  getAllGroups(useCache: boolean = false): Observable<IGroup[]> {
    return this.getDocuments<IGroup>(this.COLLECTION.CATCHUP_GROUPS, useCache);
  }

  getAllCatchups(groups: string[]) {
    // This function is designed to be used only by admins, so don't check for approved status
    const whereConditions: IWhereCondition[] = [
      {
        field: 'groupId',
        operator: 'in',
        value: groups
      }
    ];

    const orderConditions: IOrderCondition[] = [
      {
        field: 'datetime',
        direction: 'asc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.MAX_RECORDS);
    return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn);
  }

  getGroup(uid: string, useCache = false): Observable<IGroup> {
    return this.getDocument<IGroup>(this.COLLECTION.CATCHUP_GROUPS, uid, useCache);
  }

  getGroupCatchups(recordsToFetch: number, group: string, startDate: number, isShared: boolean) {
    const whereConditions: IWhereCondition[] = [];
    if (isShared) {
      whereConditions.push({
        field: 'allGroupIds',
        operator: 'array-contains',
        value: group
      });
    } else {
      whereConditions.push({
        field: 'groupId',
        operator: '==',
        value: group
      });
    }

    whereConditions.push({ field: 'approved', operator: '==', value: true });
    whereConditions.push({ field: 'datetime', operator: '>', value: startDate });

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'datetime', direction: 'asc' });

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery<ICatchup>(this.COLLECTION.CATCHUPS, queryFn);
  }

  getGroupMembers(uid: string) {
    const queryFn = ref => ref.where('catchupGroupIds', 'array-contains', uid);
    return this.getDocumentsByQuery<UserObject>(this.COLLECTION.CENTRAL_MEMBERS, queryFn);
  }

  getGroupNotices(uid: string): Observable<INotice[]> {
    return this.getDocuments<INotice>(`${this.COLLECTION.CATCHUP_GROUPS}/${uid}/${this.COLLECTION.NOTICES}`);
  }

  getGroups(country: Country): Observable<IGroup[]> {
    const whereConditions: IWhereCondition[] = [];

    if (country !== null) {
      // Admins see all countries when no state/region is specified in the search modal.
      whereConditions.push({
        field: 'country',
        operator: 'array-contains',
        value: country as string
      });
    }

    const queryFn = this.createQueryFunction(whereConditions, [], 999);
    return this.getDocumentsByQuery<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFn);
  }

  getGroupsByCohost(memberId: string): Observable<IGroup[]> {
    const whereConditions: IWhereCondition[] = [{ field: `cohosts.${memberId}`, operator: '!=', value: '' }];
    const queryFn = this.createQueryFunction(whereConditions, [], this.MAX_RECORDS);
    return this.getDocumentsByQuery<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFn);
  }

  getGroupsByHost(memberId: string): Observable<IGroup[]> {
    const whereConditions: IWhereCondition[] = [{ field: `hosts.${memberId}`, operator: '!=', value: '' }];
    const queryFn = this.createQueryFunction(whereConditions, [], this.MAX_RECORDS);
    return this.getDocumentsByQuery<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFn);
  }

  getGroupsById(ids: string[]): Observable<IGroup[]> {
    const whereConditions: IWhereCondition[] = [];
    const RECORDS_TO_FETCH = 10;

    if (ids.length === 0) {
      const queryFn = this.createQueryFunction(whereConditions, [], RECORDS_TO_FETCH); // Set number of records to fetch to 10, because "in" queries are limited a list of 10 values, so the max # of ids this query will return is 10.
      return this.getDocumentsByQuery<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFn);
    } else {
      //__name__ is a special keyword for document uid; an alternative is to use firebase.firestore.FieldPath.documentId()
      const queryFns = this.createChunkedQueryFunctions(ids, '__name__', 'in', whereConditions, false);
      return this.getDocumentsByMultipleQueries<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFns);
    }
  }

  getGroupsWithNoticesById(ids: string[], groupType: GroupType): Observable<IGroup[]> {
    const whereConditions: IWhereCondition[] = [];
    if (groupType !== null) {
      whereConditions.push({ field: 'groupType', operator: '==', value: groupType });
    }
    // Ideally we would add a whereCondition to filter out hidden groups if the group type is not set,
    // but we can't use != and in operators in the same query
    // But since the number of these will be small, filter out in groupService
    const RECORDS_TO_FETCH = 10;

    //__name__ is a special keyword for document uid; an alternative is to use firebase.firestore.FieldPath.documentId()
    const queryFns = this.createChunkedQueryFunctions(ids, '__name__', 'in', whereConditions, false);
    return this.getDocumentsByMultipleQueriesWithSubcollection<IGroup>(this.COLLECTION.CATCHUP_GROUPS, queryFns, this.COLLECTION.NOTICES);
  }

  getGroupSocials(recordsToFetch: number, groupId: string, startDate: string, showDrafts: boolean) {
    const whereConditions: IWhereCondition[] = [];

    if (!showDrafts) whereConditions.push({ field: 'approved', operator: '==', value: true });
    whereConditions.push({ field: 'date', operator: '>=', value: startDate });
    whereConditions.push({ field: 'sharedGroupIds', operator: 'array-contains', value: groupId });

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: `date`, direction: 'asc' });

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
    return this.getDocumentsByQuery<ISocial>(this.COLLECTION.SOCIALS, queryFn);
  }

  markGroupThreadForDeletion(uid: string) {
    const threadId = `group_${uid}`;
    // In principle we should use deleteDate: now, but any time earlier than now will cause the thread to be deleted next time the cleanup job runs
    this.updateDocument(this.COLLECTION.MESSAGES_THREADS, threadId, { deleteDate: 0 });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.memberSearchSubscription);
  }

  searchMembers(startsWith: string, myCountry: Country) {
    startsWith = startsWith ? startsWith.toLowerCase() : null;

    const allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries || this.authService.isAdmin([AdminRole.HOSTS, AdminRole.CONCIERGE]);
    return this.searchForUsers(startsWith, myCountry as string, false, []);
  }

  searchMembersByRole(startsWith: string, roles: Role[] = [Role.HOST]) {
    startsWith = startsWith ? startsWith.toLowerCase() : null;

    return this.searchForUsers(startsWith, '', true, roles);
  }

  updateGroup(uid: string, data: any, merge = true, updateCache = false) {
    return this.updateDocument(this.COLLECTION.CATCHUP_GROUPS, uid, data, merge, updateCache);
  }

  updateGroupName(uid: string, name: string) {
    const useCache = false;
    this.getDocument<Record<string, IValueWithId[]>>(this.COLLECTION.GROUP_NAMES, 'groups', useCache)
      .pipe(first(x => !!x))
      .subscribe(groups => {
        const data = (groups.ALL || []).filter(x => x.uid !== uid);
        data.push({ uid, name });
        this.updateDocument(this.COLLECTION.GROUP_NAMES, 'groups', { ALL: data }).then(result => {
          this.cache.clear(this.COLLECTION.GROUP_NAMES);
        });
      });
  }

  updateNotice(groupId: string, noticeId: string, data: any, merge: boolean = true) {
    return this.updateSubDocument(this.COLLECTION.CATCHUP_GROUPS, groupId, this.COLLECTION.NOTICES, noticeId, data, merge);
  }

  // Search for Firebase users and transform the returned data into something the chirpy-select-item component can use.
  // TODO if we don't want Chirpy members to see all countries, restore showAllCountries to false
  private searchForUsers(startsWith: string, myCountry: string, showAllCountries: boolean, roles: Role[] = []) {
    const subject$ = new Subject();
    this.subscriptionService.clearSubscription(this.memberSearchSubscription);
    this.memberSearchSubscription = this.userDatabase.searchMembers(startsWith, 'searchName', myCountry, showAllCountries, roles).subscribe(results => {
      const members: Record<string, string> = {};
      results.map((r: UserObject) => (members[r.uid] = r.displayName));
      subject$.next(members);
    });
    this.subscriptionService.add(this.memberSearchSubscription);
    return subject$;
  }
}
