import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { EnvironmentService } from '@shared/services/environment.service';
import { MembersGuard } from '@shared/guards/members-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'advertisers',
    loadChildren: () => import('../../advertisers/advertisers.module').then(m => m.AdvertisersModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'bed-and-breakfast',
    loadChildren: () => import('../../bnb/bnb.module').then(m => m.BnBModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'share',
    loadChildren: () => import('../../share/share.module').then(m => m.ShareModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'fun',
    loadChildren: () => import('./modules/fun/fun.module').then(m => m.FunModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'rewards',
    loadChildren: () => import('./modules/coins/coins.module').then(m => m.CoinsModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'coins',
    redirectTo: 'rewards', // Redirection for convenience
    canActivate: [MembersGuard]
  },
  {
    path: 'games',
    loadChildren: () => import('./modules/games/games.module').then(m => m.GamesPageModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'marketplace',
    loadChildren: () => import('../../marketplace/marketplace.module').then(m => m.MarketplaceModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'meeting-place',
    loadChildren: () => import('../../meeting-place/meeting-place.module').then(m => m.MeetingPlaceModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'my-chirpy',
    loadChildren: () => import('../../my-chirpy/my-chirpy.module').then(m => m.MyChirpyPageModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'social',
    loadChildren: () => import('../../social/social.module').then(m => m.SocialModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'travel-au',
    redirectTo: 'groups/50jOCeizq4wGL0genKQE' // Redirection for convenience
  },
  {
    path: 'travel-nz',
    redirectTo: 'groups/9BnrvUW2w6aeduG9qS2Z' // Redirection for convenience
  },
  {
    path: 'upgrade',
    redirectTo: 'pages/upgrade-subscription'
  },
  {
    path: 'chit-chat-au',
    redirectTo: 'groups/chit-chat/Z7kAjBPkUJGtWe1ITUWg'
  },
  {
    path: 'chit-chat-nz',
    redirectTo: 'groups/chit-chat/JKV0fDNHl3NJ2OrJ0UgZ'
  },
  //This rule must always be last
  {
    path: '**',
    redirectTo: 'error/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule]
})
export class ChirpyRoutingModule {
  routes = routes;

  constructor(private environmentService: EnvironmentService, private router: Router) {
    if (this.environmentService.settings.constants === 'chirpy') {
      this.addChirpyModuleRoutes();
    }
  }

  private addChirpyModuleRoutes() {
    const newRoutes = this.router.config.concat(routes);
    this.router.resetConfig(newRoutes);
  }
}
