import { Component, Input, OnInit } from '@angular/core';
import { range } from '@shared/functions/range';
import { Observable } from 'rxjs';
import { ILeaderboardPlayer } from '../../../models/leaderboard-player';
import { BingoService } from '../../../services/bingo.service';

@Component({
  selector: 'bingo-leader-board',
  templateUrl: 'leader-board.component.html'
})
export class BingoLeaderBoardComponent implements OnInit {
  leaderboard$: Observable<ILeaderboardPlayer[]>;
  loadingElements: number[] = [];

  @Input() numPlayersToShow = 5;

  constructor(private bingoService: BingoService) {}

  ngOnInit() {
    this.loadingElements = range(1, 5);
    this.leaderboard$ = this.bingoService.getLeaderboard(this.numPlayersToShow);
  }
}
