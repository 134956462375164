import { NgModule } from '@angular/core';
import { AdminGuard } from '@shared/guards/admin-guard';
import { SharedModule } from '@shared/shared.module';
import { BingoRoutingModule } from './bingo-routing.module';
import { BaseAnimatableComponent, BingoBallComponent, BingoCalledNumbersComponent, BingoFireworksOverlayComponent, BingoInfoPaneComponent, BingoPauseMenuComponent, BingoTicketComponent, BingoTileHeadingComponent, NoBingoIndicatorComponent } from './game/components';
import { GamePage } from './game/game.page';
import { BingoHelpNotesComponent, BingoLeaderBoardComponent, BingoPlayGameComponent } from './start/components';
import { StartPage } from './start/start.page';

@NgModule({
  imports: [BingoRoutingModule, SharedModule],
  entryComponents: [BingoHelpNotesComponent, BingoPauseMenuComponent],
  declarations: [
    // Start
    StartPage,
    BingoHelpNotesComponent,
    BingoLeaderBoardComponent,
    BingoPlayGameComponent,
    // Game
    // @ts-ignore
    BaseAnimatableComponent,
    BingoBallComponent,
    BingoCalledNumbersComponent,
    BingoFireworksOverlayComponent,
    BingoInfoPaneComponent,
    BingoPauseMenuComponent,
    BingoTicketComponent,
    BingoTileHeadingComponent,
    GamePage,
    NoBingoIndicatorComponent
  ],
  providers: [AdminGuard]
})
export class BingoPageModule {}
