import { FirebaseObject } from '@shared/models/firebase-object';

export class LeaderboardDTO extends FirebaseObject {
  games: Record<string, { gameType: string; points: number }>;
  gamesPlayed: number;
  lastGameId: string;
  lastUpdated: number;
  playerId: string;
  totalPoints: number;
}
