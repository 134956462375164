import { LimitPeriod } from '@infrastructure/constants/limit-period';
import { Country } from '@shared/constants/country';
import { Gender } from '@shared/constants/gender';
import { GroupType } from '@shared/constants/group-type';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { Role } from '@infrastructure/constants/role';
import { TripSection } from '@shared/constants/trip-section';
import { IConstants } from '../constants';

// Constants for ChirpyPlus
export const ChirpyConstants: IConstants = {
  ACCOUNT: {
    CANCEL: {
      enabled: true,
      instructions: `Please note, Chirpy Plus has a No Refund policy.`,
      reasons: [
        `Haven’t connected with other members/nothing in common`,
        `Too busy/catchups clash with other commitments eg. family, work`,
        `Catchup activities/locations are not of interest to me`,
        `Health/other issues prevent me from participating`,
        `Struggled with using the system and technology`,
        `Subscription too expensive/can’t afford/not value for money`,
        `Not enough CatchUps near me`
      ],
      showExtraInstructions: `We're sorry to see you go. To help us continually improve Chirpy Plus, we'd appreciate knowing why you're cancelling your membership.`,
      supportRequest: `I would like Chirpy Support to contact me about changing to a different subscription plan, for example upgrading from monthly to annual to save over $50`,
      yesNoQuestion: `Did you attend a CatchUp?`
    }
  },
  ADVERTISERS: {
    branding: `Advertisers`,
    enabled: true,
    headerText: ``,
    noAdvertisersMessage: `<p>Sorry, there are no advertisers at present.</p>`,
    ANNOUNCEMENTS: {
      branding: `Announcement`,
      pageHeading: `Edit announcement`,
      titlePlaceholder: `Enter a title for your announcement`
    },
    DETAIL: {
      addAnnouncementButton: `Add announcement`,
      disclaimer: ``,
      doesNotExist: `Advertiser does not exist`,
      noAnnouncements: `This advertiser has not made any announcements yet.`,
      pageHeading: `View advertiser details`,
      placeholder: `Click the Update button in the header to start adding some information about your business`
    },
    EDIT: {
      contactPlaceholder: `Enter your website or email address`,
      descriptionPlaceholder: `Enter your text here`,
      displayNamePlaceholder: `Enter your business name here`,
      loadingErrorMessage: `Cannot load data. Please try again later, or contact support.`,
      pageHeading: `Update Advertiser`,
      permissionErrorMessage: `Sorry, you don't have permission to edit this page`,
      regionInstructions: `Select the area you sell to, or where most of your clients/customers come from`
    },
    SEARCH: {
      heading: `Search Advertisers`,
      namePlaceholder: `Enter an advertiser name...`
    },
    SERVICE: {
      deleteHeader: `Delete advertiser`,
      deleteMessage: `This will permanently delete this advertiser listing. Are you sure?`,
      deletedAnnouncementToast: `Announcement has been deleted.`,
      deletedToast: `Advertiser has been deleted.`,
      updatedToast: `Advertiser has been updated.`
    }
  },
  AGE_RANGES: [
    { min: 50, max: 54, label: '50 to 54' },
    { min: 55, max: 59, label: '55 to 59' },
    { min: 60, max: 64, label: '60 to 64' },
    { min: 65, max: 69, label: '65 to 69' },
    { min: 70, max: 74, label: '70 to 74' },
    { min: 75, max: 110, label: '75 and over' }
  ],
  APP: {
    allowOtherCountries: false,
    altText: `Chirpy Plus logo`,
    countries: {
      Australia: Country.AUSTRALIA,
      'New Zealand': Country.NEW_ZEALAND
    },
    logo: `/assets/chirpy/chirpy-plus-logo.svg`
  },
  ASSETS: {
    BADGES: {
      icon: {
        advocate: {
          color: `secondary`,
          description: `Chirpy advocate: Has referred a friend to Chirpy`,
          name: `person-add`,
          title: `Chirpy advocate`
        },
        lifetime: {
          description: `Longevity award`,
          name: `trophy`,
          title: `Longevity award`
        },
        travel: {
          color: `secondary`,
          description: `Has been on one Chirpy Travel trip`,
          name: `airplane`,
          title: `Chirpy Travel 1`
        },
        travel2: {
          color: `silver`,
          description: `Has been on two Chirpy Travel trips`,
          name: `airplane`,
          title: `Chirpy Travel 2`
        },
        travel3: {
          color: `gold`,
          description: `Has been on three Chirpy Travel trips`,
          name: `airplane`,
          title: `Chirpy Travel 3`
        },
        travel4: {
          color: `platinum`,
          description: `Has been on four or more Chirpy Travel trips`,
          name: `airplane`,
          title: `Chirpy Travel 4`
        },
        'twenty-five-plus': {
          description: `Has attended 25+ Chirpy CatchUps`,
          src: `/assets/chirpy/twenty-five-plus-icon.svg`,
          title: `25+ CatchUps`
        }
      },
      role: {
        [Role.ADMIN]: {
          description: `Can access admin menu and other member's data`,
          src: ``,
          title: `Admin`
        },
        [Role.ADVISOR]: {
          src: ``,
          title: ``
        },
        [Role.HOST]: {
          description: `Can approve CatchUps, create messages with >6 members, etc`,
          src: `/assets/chirpy/host-icon.svg`,
          title: `Chirpy Host`
        },
        [Role.COHOST]: {
          description: `Can create and manage CatchUps`,
          src: `/assets/chirpy/cohost-icon.svg`,
          title: `Chirpy Co-Host`
        },
        [Role.MEMBER]: {
          description: `No special permissions`,
          src: ``,
          title: `Member`
        }
      }
    },
    defaultGroupImage: `/assets/chirpy/chirpy-icon.png`,
    defaultImage: `/assets/chirpy/chirpy-icon.png`,
    placeholderImage: `/assets/chirpy/chirpy-icon.png`
  },
  BNB: {
    branding: `Chirpy Bed and Breakfast`,
    DETAIL: {
      doesNotExist: `Listing does not exist`,
      pageHeading: `View Chirpy Bed & Breakfast Listing`,
      underReview: `This listing is being reviewed by Chirpy Support, it is not available to members yet.`
    },
    EDIT: {
      createButtonText: `Create a listing`,
      createPageHeading: `Create listing`,
      maxPhotos: 5,
      fields: [
        {
          id: `separate-bedroom`,
          required: false,
          title: `Separate bedroom`,
          type: `checkbox`
        },
        {
          id: `ensuite-bathroom`,
          required: false,
          title: `Ensuite bathroom`,
          type: `checkbox`
        },
        {
          id: `shared-toilet`,
          required: false,
          title: `Shared toilet`,
          type: `checkbox`
        },
        {
          id: `shared-shower`,
          required: false,
          title: `Shared shower`,
          type: `checkbox`
        },
        {
          id: `free-wifi`,
          required: false,
          title: `Free wifi`,
          type: `checkbox`
        },
        {
          id: `parking`,
          placeholder: `Is parking available off-street or on-street?`,
          required: true,
          title: `Parking`
        },
        {
          id: `noise-level`,
          placeholder: `Is the property next to railway or busy road?`,
          required: true,
          title: `Noise level`
        },
        {
          id: `pets`,
          placeholder: `Are pets allowed?`,
          required: true,
          title: `Pets`
        },
        {
          id: `couples`,
          placeholder: `Are couples accepted?`,
          required: true,
          title: `Couples`
        },
        {
          id: `guests-gender`,
          placeholder: `Do you accept only male or female guests?`,
          required: true,
          title: `Gender restrictions`
        },
        {
          id: `stay-length`,
          placeholder: `Do you have a minimum or maximum stay?`,
          required: true,
          title: `Stay length`
        },
        {
          id: `air-conditioning`,
          placeholder: `Do you have air-con or a heat pump?`,
          required: true,
          title: `Air conditioning`
        },
        {
          id: `access`,
          placeholder: `Are there stairs?`,
          required: true,
          title: `Access`
        },
        {
          id: `local-amenities`,
          placeholder: `Walks, restaurants, public transport,...`,
          required: true,
          title: `What is in the neighbourhood?`
        }
      ],
      instructions: `
      <p>To list your room on Chirpy Bed and Breakfast, please fill out the form below. Once we have reviewed your listing, a Chirpy staff member will publish it. If we need to confirm any details before publishing your listing, we will get in touch with you.</p>
      <p>Please note that Chirpy Bed and Breakfast serves only to connect Chirpies who want to rent out their spare rooms with those who want to stay in them. Chirpy is not responsible for setting the room rate or making/collecting payments.</p>
      <p>Note that the income you earn from renting out your room is tax-exempt if your room rate is below a certain amount, and you don’t exceed the maximum number of rental days per tax year. For the full information, click <a target="_blank" href="https://www.ird.govt.nz/property/renting-out-residential-property/residential-rental-income-and-paying-tax-on-it/rules-for-working-out-rental-income-and-expenses/short-stay-standard-cost-for-renting-out-your-home-or-its-rooms">HERE</a>.</p>
      <p>By submitting your listing, you agree to the following rules:</p>
      <ul>
        <li>You will set your own room rate, which should be more affordable than a commercial B&B of a similar standard. (We recommend average of $50 per night, and $60 per night if it includes your own ensuite.)</li>
        <li>You are responsible for collecting payments from your guests. Chirpy will not be involved in any disputes between parties.</li>
        <li>You are responsible for adhering to any tax and insurance requirements that arise from renting out your spare room. Note the link above to the IRD information.</li>
        <li>You must live at the property.</li>
        <li>You must hold a current annual Chirpy membership or have been a monthly member for over 12 months, to use the Chirpy Bed and Breakfast service.</li>
        <li>You will provide your guest with breakfast, but it doesn’t need to be a cooked meal – toast, cereal, fruit, and yoghurt are fine.</li>
      </ul>`,
      PLACEHOLDERS: {
        cost: `Enter the cost per night`,
        description: `Describe the accomodation`,
        location: `Select the suburb or town closest to the accommodation`,
        title: `Enter a title for your listing`
      },
      updateButtonText: `Update listing`,
      updatePageHeading: `Update listing`
    },
    enabled: true,
    introPage: `/pages/chirpy-bed-and-breakfast`,
    LIST: {
      pageHeading: `Chirpy Bed and Breakfast`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    REVIEW: {
      instructions: `Please comment below on your experience at this Chirpy Bed and Breakfast so that we can ensure this listing is accurate for other Chirpy members.`,
      placeholder: `Describe your experience at this Chirpy Bed and Breakfast`
    },
    SEARCH: {
      pageHeading: `Search Listings`
    },
    SERVICE: {
      deletedReviewToast: `Chirpy Bed and Breakfast review has been deleted.`,
      deletedToast: `Chirpy Bed and Breakfast listing has been deleted.`,
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deleteReviewHeader: `Delete review`,
      deleteReviewMessage: `This will permanently delete this review. Are you sure?`
    }
  },
  CATCHUPS: {
    ATTENDANCE: {
      noData: `You have not yet attended any CatchUps.`
    },
    branding: `CatchUp`,
    CLAIM: {
      disclaimer: `By filling in this form, you are declaring that the information you have provided is true and correct. We will perform random checks to ensure reward claims are genuine. Any member found to be falsely claiming CatchUp rewards will forfeit their entire rewards balance.`,
      excludeRoles: [Role.HOST],
      excludeRoleMessages: {
        [Role.HOST]: `Host of this group`
      },
      instructions: `<p>To claim rewards for attending a CatchUp, you must:</p>
                  <ul>
                    <li>Have RSVPed before the CatchUp</li>
                    <li>Attend the CatchUp</li>
                    <li>Claim your rewards within two days of attending the CatchUp</li>
                  </ul>
                <p>You can claim rewards for a maximum of 10 CatchUps per calendar month.</p>`,
      noCatchups: `Sorry, there are no CatchUps that you can claim rewards for.`,
      PERIOD: {
        first: 21,
        second: 45
      }
    },
    DETAIL: {
      disclaimer: `<p>We sometimes publish CatchUp photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.</p>`,
      noMessages: `<p>No notes yet. If you've got a question or something to say about the CatchUp, write it below.</p>`,
      underReview: `Please check that all information entered is correct before you approve this CatchUp.`
    },
    EDIT: {
      disclaimer: `Chirpy Plus reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the Chirpy Plus community. All events and listings are reviewed by a member of the Chirpy Plus team before they are published.`,
      noResultsText: `CatchUps cannot be shared with groups which already have a CatchUp on the same day.`,
      populateDescription: `All Chirpy members welcome!

Come along and socialise with your Chirpy host and other Chirpy members.

RSVP is essential
To RSVP, just click on the 'RSVP' button.

Can’t make it?
If you’ve RSVPd but need to cancel, please let us know so we can manage the table booking. You can click on the RSVP button to un-attend.

A polite reminder that Chirpy CatchUps are a safe zone and attending members are not permitted to sell or promote their own products/services.

We sometimes publish CatchUp photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.

`,
      selectDateFirstMessage: `Please select a date first, so we can check for potential clashes.`
    },
    LIST: {
      isSearchEnabled: true,
      title: `CatchUps`
    },
    TEMPLATE: {
      instructions: `<p>Enter as many or as few fields as you need to facilitate creating CatchUps. The only required field is the template name.</p>
      <p>The changes you make to this template will not affect existing CatchUps.</p>`
    }
  },
  COINS: {
    branding: `rewards`,
    earnMoreButton: `Find out more`,
    earnMoreCallToAction: `Discover more ways to boost your Chirpy Rewards balance. Refer two new annual members and get $50!`,
    enabled: true,
    levels: [
      {
        icon: `/assets/chirpy/coins/blue-icon.svg`,
        name: `blue`,
        order: 0,
        threshold: 0
      },
      {
        icon: `/assets/chirpy/coins/silver-icon.svg`,
        name: `silver`,
        order: 1,
        threshold: 10000
      },
      {
        icon: `/assets/chirpy/coins/gold-icon.svg`,
        name: `gold`,
        order: 2,
        threshold: 15000
      },
      {
        icon: `/assets/chirpy/coins/platinum-icon.svg`,
        name: `platinum`,
        order: 3,
        threshold: 20000
      }
    ],
    levelSuffix: ` Level`,
    nextLevelMessage: `get to your`,
    retainLevelMessage: `retain your`,
    showLevelsFrom: 1,
    site: `Chirpy`,
    title: `Chirpy Rewards`
  },
  CONTACT_US: {
    emailSubject: `Chirpy Plus Contact Us`,
    footerText: `We will contact you shortly to help with your enquiry.`
  },
  EVENTS: {
    CLAIM: {
      disclaimer: ``,
      instructions: ``,
      noCatchups: ``
    },
    DETAIL: {
      disclaimer: `<p>We sometimes publish Event photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.</p>`,
      title: `Detail title`,
      underReview: `Please check that all information entered is correct before you approve this Event.`
    },
    EDIT: {
      addressDisclaimer: ``,
      createButtonText: `Create`,
      createPageHeading: `Create an Event`,
      disclaimer: `ChirpyPlus reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the Chirpy community. All events and listings are reviewed by a member of the Chirpy team before they are published.`,
      mapInstructions: ``,
      updateButtonText: `Update`,
      updatePageHeading: `Update Event`
    },
    INTERESTED: {
      title: `Interested title`
    },
    LIST: {
      isEventSubmissionEnabled: false,
      isSearchEnabled: true,
      title: `Events`
    },
    REGISTER: {
      instructions: ``,
      subject: ``,
      successMessage: ``
    },
    SEARCH: {
      heading: `Search heading`
    }
  },
  GENDERS: [
    {
      name: 'Rather not say',
      value: Gender.NOT_SPECIFIED,
      avatarUrl: `/assets/chirpy/gender/no-gender-icon.svg`,
      default: 'true'
    },
    {
      name: 'Male',
      value: Gender.MALE,
      avatarUrl: `/assets/chirpy/gender/male-icon.svg`
    },
    {
      name: 'Female',
      value: Gender.FEMALE,
      avatarUrl: `/assets/chirpy/gender/female-icon.svg`
    }
  ],
  GROUPS: {
    disableMemberJoinedNotifyHost: false,
    CHIT_CHAT: {
      branding: `Chit-Chat`,
      noMessages: `No-one's said anything yet. Why not get the ball rolling? Post a message at the bottom of the screen.`
    },
    DETAIL: {
      catchupsHeading: `CatchUps`,
      createSocialsMessage: `If you would like to organise a Social for your group, click the button below.`,
      disclaimer: `We sometimes publish CatchUp photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.`,
      noSocialsMessage: `There are no socials for this group at present.`,
      socialsButton: `Create a social`,
      socialsHeading: `Socials`
    },
    EDIT: {
      createButtonText: `Create`,
      createPageHeading: `Create a group`,
      hasAdvisors: false,
      hasCohosts: true,
      updateButtonText: `Update`,
      updatePageHeading: `Update group`
    },
    GROUP_TYPES: [
      {
        name: 'Hidden groups',
        groupType: GroupType.HIDDEN_GROUP,
        hidden: true,
        protected: true
      },
      {
        name: 'Groups',
        groupType: GroupType.GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Special interest groups',
        groupType: GroupType.SPECIAL_INTEREST_GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Host-only groups',
        groupType: GroupType.HOST_ONLY_GROUP,
        hidden: false,
        protected: true
      },
      {
        name: 'Chat group',
        groupType: GroupType.CHAT_GROUP,
        hidden: false,
        protected: false
      }
    ],
    LIST: {
      headerText: `<p>Welcome to Chirpy Groups! Join a group to keep up-to-date with all the group's events and chat online with other group members.</p>
      <p>Use the search form or browse the list or map to find a group near you. Click on a group for more details, then when you find a group you want to join, all you need to do is click the "Join this group" button. Remember, you can belong to more than one group!</p>
      <p>If you'd like to search for a Special Interest Group (SIG), tick the "show only Special Interest Groups" checkbox on the search form.</p>`,
      mapHeaderText: null,
      noGroupsMessage: {
        [GroupType.GROUP]: `<p>Sorry, no local groups match your search criteria. If you'd like to suggest a new group, please <a target="_blank" href="/contact-us">contact support</a>.</p>`,
        [GroupType.SPECIAL_INTEREST_GROUP]: `<p>Sorry, no special interest groups match your search criteria. If you'd like to suggest a new group, please <a target="_blank" href="/contact-us">contact support</a>.</p>`
      }
    },
    NOTIFICATIONS: {
      instructions: `
      <li>When something new is posted in this group, we send you a notification.</li>
      <li>You can choose whether to receive an email straight away, or have it included in the "Daily Digest" email with all other notifications from the past 24 hours.</li>
      <li>Use the toggles below to select your preferences, then click "Save Changes".</li>
      <li>You can change settings for other notifications on the <a href="/account/notifications">Manage notifications</a> page, and you can see your notifications at any time by going to your <a href="/my-chirpy/activity">activity feed</a>.
      `
    },
    SEARCH: {
      heading: `Search Groups`,
      sigLabel: `Special interest groups`
    },
    SOCIALS: {
      cantCreateMessage: `As a host or co-host you cannot create socials. If you want to create an event, please create a CatchUp instead.`,
      createButtonLink: `/social/update/new`,
      createButtonText: `Create Social`,
      createSocialsMessage: `If you would like to organise a Social for your group, click the button below.`,
      heading: `Socials`,
      noSocialsMessage: `No forthcoming socials in this group.`,
      title: 'Group Socials'
    }
  },
  LOGIN: {
    logo: `/assets/chirpy/chirpy-plus-blue.svg`,
    heading: `Member Login`,
    text: ``,
    footerLogo: ``
  },
  MARKETPLACE: {
    branding: `Marketplace`,
    enabled: true,
    disclaimer: `<p>Chirpy Marketplace is an online market exclusively available to Chirpy members. You may use it to sell something in any of the listed categories, as long as it complies with the following rules.</p>
<ul><li>You must be the legal owner of the item(s) you are selling.</li>
<li>You may not sell items that require special sales permits or are illegal (for example, counterfeit items, alcohol, drugs, tobacco, fireworks, or firearms), intimate apparel, offensive items, event tickets, or digital currency.</li>
<li>You can not include a private phone number or email address in your listing. To protect your privacy, all correspondence must be completed via Chirpy Messages.</li></ul>
<p>Chirpy reserves the right to remove any listing that breaches these rules, or any other listing that it deems unsuitable. Chirpy does not take any commission on, or get involved in any arrangements that you make between yourself and another member. If you receive a Chirpy Message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the Chirpy Messages screen. Don't give out any personal information such as your home address, email address or phone number unless it is necessary to complete the transaction.</p>`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their Chirpy Marketplace listing you are agreeing to abide by the <a href="https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Listing does not exist`,
      pageHeading: `View Chirpy Marketplace Listing`,
      underReview: `This listing is being reviewed by Chirpy Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: `https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct`,
      createButtonText: `Add Listing`,
      createPageHeading: `Add a listing`,
      descriptionPlaceholder: `Describe what you're selling`,
      locationPlaceholder: `Where you are, or where you're going`,
      locationWarning: `For your privacy please do not put your street address here.`,
      maxPhotos: 1,
      showGenderFilter: true,
      terms: [
        {
          id: `profileCurrent`,
          required: true,
          title: `My profile information is up to date`
        },
        {
          id: 'profilePhoto',
          required: true,
          title: `My profile includes a current photo`
        },
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the Chirpy Marketplace disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct" target="_blank" class="checkbox-label-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `TERMS AND CONDITIONS`,
      titlePlaceholder: `e.g. Second-hand clothes, Hand-made jewellery, Typing service...`,
      updateButtonText: `Update Listing`,
      updatePageHeading: `Update listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [Gender.MALE, Gender.FEMALE],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `Chirpy Marketplace`,
      pageIntro: `<p>Chirpy Marketplace is an online market, exclusive to Chirpy members, where you can sell your preloved items or things that you have crafted. It's so easy - just use the button below to list the item you'd like to sell. Or search the Chirpy Marketplace to see if anyone is selling something you'd like to buy. You can use Chirpy Marketplace to sell things like second-hand clothing, accessories, bric-a-brac, books, CDs, DVDs, musical instruments, and more. Or handcrafted items like paintings, pottery, macrame, knitting, sewing, quilting, beads, charms, and woodwork. You can also sell services like typing, music lessons, or lawn mowing. Best of all, there are no success fees!</p>`,
      pageTitle: `Welcome to Chirpy Marketplace`,
      options: [
        {
          heading: `Look for something to buy`,
          routerLink: `/marketplace`,
          text: `Discover what Chirpy members have for sale`
        },
        {
          heading: `Sell something`,
          routerLink: `/marketplace/update/new`,
          text: `List something that you'd like to sell to other Chirpies`
        }
      ]
    },
    LIST: {
      pageHeading: `Chirpy Marketplace`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      categoryPlaceholder: `Search categories...`,
      pageHeading: `Search Listings`
    },
    SERVICE: {
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `Chirpy Marketplace listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`
    }
  },
  MEDIA: {
    branding: `Chirpy Pix`,
    publicCountries: [Country.AUSTRALIA],
    quotas: {
      [MediaCollectionType.GROUP]: 50,
      [MediaCollectionType.MEMBER]: 10,
      [MediaCollectionType.PUBLIC]: -1
    }
  },
  MEETING_PLACE: {
    instructions: `Find and message local Chirpies around you to say hi! Looking for company going to the movies or a walk? It’s easy - <a href="/social">just create a social.</a>`,
    pageHeading: `Meeting Place`,
    searchHeading: `Search Members`
  },
  MESSAGES: {
    CONTACTS: {
      LIST: {
        onboardingMessage: `You haven't added any contacts yet.\nClick the orange + button below to add a Chirpy member to your contacts list.`
      }
    },
    MORE: {
      ARCHIVE: {
        button: 'Archive',
        header: `Archive messages`,
        message: `Select a date to archive all threads where the last message was received before this date`,
        onboardingMessage: `You haven't archived any messages yet.`
      }
    },
    THREADS: {
      DETAIL: {
        isBlockedByMessage: `You cannot reply to this member because they have blocked you.`,
        isBlockedMessage: `You cannot reply to this member because you have blocked them.`,
        isSoleMemberMessage: `You cannot reply to this person because they are no longer a member of Chirpy Plus.`,
        moderatedMessage: `This message has been moderated by Chirpy Support`
      },
      LIST: {
        onboardingMessage: `You haven't received any messages yet.\nClick the orange + button below to send a message to another Chirpy member.`
      }
    }
  },
  MY_CHIRPY: {
    tabs: {
      mine: {
        title: `My Chirpy`,
        hasUnread: false,
        name: `contact`
      },
      activity: {
        title: `Activity`,
        hasUnread: true,
        name: `film`
      }
    },
    iconLayout: `icon-top`,
    boxes: [
      {
        color: `primary`,
        isButtonEnabled: false,
        link: `/catchups`,
        placeholder: `RSVP to a CatchUp`,
        size: 12,
        title: `My Events`,
        type: `events`
      },
      {
        align: 'right',
        color: `primary`,
        title: `Claim rewards`,
        type: `buttons`
      },
      {
        color: `secondary`,
        link: `/groups`,
        placeholder: `Join a group`,
        title: `My Groups`,
        type: `groups`
      },
      {
        color: `primary`,
        link: `/rewards`,
        placeholder: `View all details`,
        title: `My Rewards`,
        type: `coins`
      },
      {
        color: `tertiary`,
        link: `/catchups/attendance`,
        placeholder: `View attended CatchUps`,
        title: `My CatchUps`,
        type: `catchups`
      },
      {
        color: `secondary`,
        groupType: GroupType.HIDDEN_GROUP,
        hideIfEmpty: true,
        link: `/travel-au`, //TODO: Link this to correct country
        placeholder: `Find a trip`,
        title: `My Trips`,
        type: `groups`
      }
    ]
  },
  NOTIFICATIONS: {
    headings: {
      Advertiser: 'Advertisers',
      Catchup: 'CatchUps',
      Group: 'Groups',
      Member: 'Mentions',
      Message: 'Messages',
      Romance: 'Romance',
      Social: 'Socials'
    },
    instructions: `
      <li>By default we send you a "Daily Digest" email once a day. This list all your notifications which have occurred for the previous 24 hours.</li>
      <li>If you do not wish to receive this Daily Digest, turn Digest to OFF with the toggle button below and click "Save Changes".</li>
      <li>However, if you wish to be notified immediately about messages and activities on Chirpy, use the settings on this page to turn on the notifications you are most interested in.</li>
      <li>And remember, you can check your notifications at any time by going to your <a href="/my-chirpy/activity">activity feed</a>.
    `,
    settings: {
      Message: [
        {
          id: 'directMessage',
          title: 'Messages only to you',
          required: true,
          type: 'advanced'
        },
        {
          id: 'conversation',
          required: true,
          title: 'Messages to you and others',
          type: 'advanced'
        }
        // Too confusing to members to distinguish between MemberThreadType.Conversation and MemberThreadType.Group
      ],
      Group: [
        {
          id: 'catchupCreatedNotifyMember',
          required: true,
          title: 'New CatchUp',
          type: 'advanced'
        },
        {
          id: 'newChitChat',
          required: true,
          title: 'New chit-chat',
          type: 'advanced'
        },
        {
          id: 'newChitChatReply',
          required: true,
          title: `New reply in a conversation you're part of`,
          type: 'advanced'
        },
        {
          id: 'newNotice',
          required: true,
          title: 'New group notice',
          type: 'advanced'
        },
        {
          id: 'newMedia',
          isHidden: true,
          required: true,
          title: 'New group photo',
          type: 'advanced'
        },
        {
          id: 'newMediaComment',
          isHidden: true,
          required: true,
          title: 'New comment on group photo',
          type: 'advanced'
        },
        {
          id: 'socialCreatedNotifyGroup',
          required: true,
          title: 'New Social',
          type: 'advanced'
        }
      ],
      Catchup: [
        {
          id: 'catchupCancelledNotifyMember',
          required: true,
          title: 'CatchUp cancelled'
        },
        {
          id: 'newCatchupNote',
          required: true,
          title: 'New note on a CatchUp'
        },
        {
          id: 'newCatchupReply',
          required: true,
          title: 'New reply to a note on CatchUp'
        }
      ],
      Social: [
        {
          id: 'socialCancelledNotifyMember',
          required: true,
          title: 'Social cancelled'
        }
      ],
      Romance: [
        {
          id: 'newRomanceListing',
          required: true,
          title: 'New listing meeting your criteria'
        }
      ],
      Advertiser: [
        {
          id: 'newAnnouncement',
          required: true,
          title: 'New announcement',
          type: 'advanced'
        }
      ]
      /* NOT USED
      Place: [
        {
          id: 'socialCreatedNotifyNearby',
          isHidden: true,
          required: true,
          title: 'New Social nearby'
        },
        {
          id: 'memberJoinedNotifyNearby',
          isHidden: true,
          required: true,
          title: 'New member joined nearby'
        }
      ],
      Country: [
        {
          id: 'newNews',
          required: true,
          title: 'Chirpy News posted'
        }
      ],*/
    }
  },
  PROFILE: {
    DOB: {
      disclaimer: `<p><small> I understand my birthdate will not be published or made available to any other Chirpy members.</small></p>`,
      enabled: true,
      icon: ``,
      label: `Date of birth (optional)`,
      minAge: 50,
      maxAge: 110,
      placeholder: `dd/mm/yyyy`
    },
    GENDER: {
      enabled: true,
      label: `Gender`
    },
    INTERESTS: {
      enabled: true,
      icon: `heart`,
      label: `Interests`,
      placeholder: `Search for interests...`
    },
    onboardingMessage: `The following fields are mandatory and must be completed before you can start enjoying Chirpy: <strong>Display Name, Gender, and Location</strong>.`,
    ORGANISATIONS: {
      enabled: true,
      icon: `contacts`,
      label: `Organisations`,
      placeholder: `Search for organisations...`
    },
    PHONE: {
      disclaimer: `<p><small>Your phone number is kept private to Chirpy Staff and your local host. </small></p>`,
      enabled: true,
      icon: ``,
      isRequired: true,
      label: `Phone`,
      placeholder: `Enter your phone number`
    },
    TITLE: {
      enabled: false,
      icon: ``,
      label: ``,
      placeholder: ``
    }
  },
  RESET_PASSWORD: {
    HEADING: {
      auto: `Set Password`,
      manual: `Reset Password`
    },
    logo: `/assets/chirpy/chirpy-plus-blue.svg`,
    MESSAGE: {
      auto: `You need to set a password. Please check your email address, and click the button below. You will receive a link to create a password via email.`,
      manual: `Forgot your password? Please enter your email address. You will receive a link to create a new password via email.`
    }
  },
  ROMANCE: {
    enabled: true,
    DETAIL: {
      doesNotExist: `Romance listing does not exist`,
      pageHeading: `View Romance Listing`,
      underReview: `This Romance Profile is being reviewed by Chirpy Support, it is not available to members yet.`
    },
    EDIT: {
      createButtonText: `Add Listing`,
      createPageHeading: `Add a listing`,
      disclaimer: `Chirpy Plus reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the Chirpy Plus community. All events and listings are reviewed by a member of the Chirpy Plus team before they are published.`,
      updateButtonText: `Update Listing`,
      updatePageHeading: `Update my listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [Gender.MALE, Gender.FEMALE, Gender.EVERYONE],
    INTRO: {
      alert: {
        button: `OK`,
        header: `Registration Request`,
        message: `<p>Thanks for submitting a request. Our support team is processing it and will get back to you.</p> `
      },
      disclaimer: `<h2 class="romance-intro__disclaimer-heading">Chirpy Romance Code of Conduct</h2><p>Your use of Chirpy Romance is subject to you holding a Chirpy Plus subscription and agreeing to adhere to the <a href="https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct" target="_blank">Chirpy Code of Conduct</a>. If you breach the code of conduct, we may cancel your Chirpy membership and website access. In this case, no refunds will be given, and our decision will be non-negotiable.</p>`,
      ineligibleInstructions: `Sorry, you are not eligible for access to Romance as you are not on an annual plan. If you would like to gain access, please <a href="/upgrade">upgrade your subscription</a>.`,
      pageHeading: `Chirpy Romance`,
      pageIntro: `<p>Are you interested in <strong>more</strong> than friendship? <strong>Chirpy Romance</strong> is a moderated, secure way to connect with others.</p>
      <p>For this reason, only members with an annual subscription to Chirpy can access Romance. To gain access, please follow these two simple steps.</p>`,
      pageTitle: `Welcome to Chirpy Romance`,
      pictureInstructions: `<h3>Step 1:</h3>
        <p>Your profile photo <strong>must</strong> reflect a true picture of yourself and must not include other people. Your current profile photo is shown below. If your photo does not meet our guidelines you can change it by clicking the <strong>Update Photo</strong> button. </p>`,
      requireListing: true,
      submitButton: `Create Listing`,
      submitInstructions: `<h3>Step 2:</h3>
      <p>The next step is to create your Chirpy Romance listing. Once you've submitted your listing, it will be reviewed by Chirpy Support and then published to Chirpy Romance. You'll receive an email to let you know when it's been approved. To proceed, click the <strong>Create Listing</strong> button below..</p>`
    },
    LIST: {
      pageHeading: `Looking for Romance`,
      noRecordsFound: `No romance listings found matching your criteria`
    },
    SEARCH: {
      pageHeading: `Search Romance Listings`
    },
    SERVICE: {
      branding: `Romance Listing`,
      deleteHeader: `Delete romance listing`,
      deleteMessage: `This will permanently delete this romance listing. Are you sure?`,
      deletedToast: `Romance listing has been deleted.`,
      hiddenToast: `Your romance listing has been hidden.`,
      updatedProfileToast: `Your romance listing has been `,
      updatedMemberProfileToast: `Romance listing has been `,
      publishApproved: `Your romance listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your romance listing has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`,
      reviewEmailSubject: `Romance profile to review:`
    }
  },
  SHARE: {
    branding: `Share`,
    enabled: true,
    disclaimer: `Chirpy Share is a classifieds service exclusively available to Chirpy members. You may use it to share something in any of the listed categories but you may not use it to advertise for a romantic partner. Chirpy does not take any commission on, or get involved in any arrangements that you make between yourself and another member. To protect your privacy, please keep your correspondence within the Chirpy Messages platform until you are certain that the other party is trustworthy. If you receive a Chirpy Message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the Chirpy Messages screen. Don't give out any personal information such as your home address, email address or phone number until it is necessary. Chirpy reserves the right to limit the number of listings per member.`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their Chirpy Share listing you are agreeing to abide by the <a href="https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Listing does not exist`,
      pageHeading: `View Chirpy Share Listing`,
      underReview: `This listing is being reviewed by Chirpy Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: `https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct`,
      createButtonText: `Add Listing`,
      createPageHeading: `Add a listing`,
      descriptionPlaceholder: `Describe what you're offering, or what you're looking for`,
      locationPlaceholder: `Where you are, or where you're going`,
      locationWarning: `For your privacy please do not put your street address here.`,
      maxPhotos: 3,
      showGenderFilter: true,
      terms: [
        {
          id: `profileCurrent`,
          required: true,
          title: `My profile information is up to date`
        },
        {
          id: 'profilePhoto',
          required: true,
          title: `My profile includes a current photo`
        },
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the Chirpy Share disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://chirpy-plus.helpscoutdocs.com/article/281-chirpy-plus-code-of-conduct" target="_blank" class="checkbox-label-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `TERMS AND CONDITIONS`,
      titlePlaceholder: `e.g. Share a car, travel companion wanted, spare room available...`,
      updateButtonText: `Update Listing`,
      updatePageHeading: `Update listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [Gender.MALE, Gender.FEMALE],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `Chirpy Share`,
      pageIntro: `<p>Chirpy Share is a classifieds service exclusive to Chirpy members that helps you to share experiences, costs, and even your spare room with other Chirpies. It's so easy - just use the button below to list the thing you'd like to share. Or search the list of Shares to see if anyone is sharing something that you'd like to do too. You can use Chirpy Share to do things like find a travel buddy, save money on excursions by booking together, share a ride between cities, earn some money from your spare room, or show people around your area. Plus, it's a fantastic way to make new friends!</p>`,
      pageTitle: `Welcome to Chirpy Share`,
      options: [
        {
          heading: `Find a Chirpy Share`,
          routerLink: `/share`,
          text: `Discover what Chirpy members want to share with you`
        },
        {
          heading: `List a Chirpy Share`,
          routerLink: `/share/update/new`,
          text: `List something that you'd like to share with other Chirpies`
        }
      ]
    },
    LIST: {
      pageHeading: `Chirpy Share`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      pageHeading: `Search Listings`
    },
    SERVICE: {
      branding: `Chirpy Share Listing`,
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `Chirpy Share listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`
    }
  },
  SOCIAL: {
    branding: `Social`,
    DETAIL: {
      approve: `Publish`,
      reject: `Unpublish`,
      title: `View Social`,
      underReview: `This Social is in Draft status. To make it available to other members click Publish.`
    },
    disclaimer: `We sometimes publish Social photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.`,
    EDIT: {
      addressDisclaimer: `For the security of our members, Socials must happen in a public place.`,
      createButtonText: `Create`,
      createPageHeading: `Create a Social`,
      mapInstructions: `Choose the nearest suburb or town to your Social. We will use this to show a marker on the map.`,
      shareInstructions: `If you want to, you can advertise your Social in your local Chirpy groups`,
      updateButtonText: `Update`,
      updatePageHeading: `Update Social`
    },
    enabled: true,
    hasMap: false,
    INTERESTED: {
      enabled: false,
      title: `Attendees list`
    },
    limitPeriod: LimitPeriod.WEEK,
    LIST: {
      headerText: `<p>Welcome to Chirpy Socials!</p>`,
      notApprovedText: `Draft`
    },
    onlyInGroup: true,
    PAGE: {
      cantCreateMessage: `As a host or co-host you cannot create socials. If you want to create an event, please create a CatchUp instead.`,
      createButtonLink: `/social/update/new`,
      createButtonText: `Create a Social`,
      mineLabel: `My Socials`,
      shortName: `Social`,
      title: `Socials`
    },
    requiresAdminApproval: false,
    SEARCH: {
      heading: `Search Socials`
    }
  },
  TRIPS: {
    DETAIL: {
      draftNotice: `This trip is in draft status and is not visible to members. To publish it click the "Publish" button above`,
      title: `Trip details`
    },
    REGISTER: {
      instructions: `<p>Thank you for your interest in this Chirpy Trip. Please submit the form below, and our travel team will answer any questions and assist you with the booking process.</p>`,
      subject: `Chirpy Trip Registration`,
      successMessage: `Thank you for registering your interest. We have forwarded your details to our travel team who will be in contact shortly.`
    },
    ROOM_SHARE: {
      disclaimer: `<p><em>Disclaimer: By using Room Share, you agree that it is your responsibility to provide an accurate and truthful personal description, and to select your roommate based on the personal descriptions provided by the other members going on this trip. Chirpy Plus is not liable for, or responsible for resolving, any disputes that arise between you and your roommate.</em></p>`,
      descriptionIntro: `<p>Your trip will be even more fun when you share it with a like-minded roommate. Therefore, please use this section to describe yourself and include information that would be important for your room buddy to know. For example</p>
      <ul>
        <li>What time do you like to go to bed?</li>
        <li>Are you a morning person or do you prefer to sleep in?</li>
        <li>Do you snore, talk, or walk in your sleep?</li>
        <li>Do you wear any sleep aids or devices?</li>
        <li>Do you have any pet peeves or phobias?</li>
        <li>Are you an introvert or an extrovert?</li>
        <li>Chatty or quiet?</li>
        <li>What are your favourite hobbies, authors, TV shows, or music?</li>
        </ul>
        <p>The more information you can provide, the better chance you'll have of finding someone you'll enjoy sharing with!</p>`,
      listIntro: `<p>First create your own listing. Then browse through the list of members wanting to share a room, and make contact with someone you think will be compatible. Once you've both agreed, go to Your Matches and add the other member as a match.</p>`,
      requirementMessage: `<p>Sorry, you need to register your interest for the trip before you can add a Room Share listing</p>`
    },
    SECTIONS: [TripSection.OVERVIEW, TripSection.ITINERARY, TripSection.PRICE, TripSection.INCLUDED, TripSection.NOT_INCLUDED, TripSection.OTHER_INFO, TripSection.TERMS_AND_CONDITIONS]
  },
  WELCOME: {
    PASSWORD: {
      buttonColor: `primary`,
      pleaseWaitText: `<p>Please wait while we create your account. This may take up to 30 seconds.</p>`,
      pleaseWaitImage: ``,
      spinner: ``,
      welcomeImage: ``,
      welcomeText: `<p>Welcome to Chirpy Plus! Thank you for joining the Chirpy family. Before you can access the Chirpy app and start living the Chirpy life, you’ll need to set up your account. It’s important you complete these steps so you can make the most of your Chirpy experience.</p>
      <p>First, please create a password. Once you have entered it below, click SET PASSWORD to continue.</p>`
    },
    SIGNUP: {
      buttonColor: `primary`,
      countLastStep: false,
      DISPLAY_NAME: {
        image: ``,
        instructions: `<p>Please choose your Chirpy display name. This is the name that will show on your profile and next to any comments you make or messages you send.</p>
        <p>Most members choose their first name followed by an initial or a number, e.g., JamesB or James101. For security, please don’t use your full name or email address.</p>
        <p><Once you’ve chosen your display name, click NEXT to continue./p>`,
        label: `Enter a Display Name`,
        title: `Display Name`
      },
      GENDER: {
        image: ``,
        instructions: `<p>To help our members get to know each other better, we encourage Chirpies to specify their
gender. Please select one option below and click NEXT to continue.</p>`,
        label: `Gender`,
        title: `Gender`
      },
      GROUPS: {
        image: ``,
        INSTRUCTIONS: {
          all: `<p>The best way to make friends and have fun is to join your local CatchUp group.</p>
                <p>You can join as many groups as you like, which is perfect if you are very social or like to travel around the country.</p>`,
          foundGroups: `<p>Here are your nearest groups. Select those that you’d like to join and click NEXT.</p>`,
          noGroups: `<p>Sorry, we can't automatically determine the closest groups to you. Please have a look on the Groups page after you've finished the signup wizard.</p>`
        },
        label: `Groups`,
        navigationTitle: `Groups`,
        skip: `Continue without joining a group`,
        title: `Finally, join a CatchUp Group`
      },
      LOCATION: {
        image: ``,
        instructions: `<p>To help you find local members and CatchUp groups, we need to know your general location. Don’t worry – for your security, we’ll never ask you for your full address!</p>
        <p>Please select your state/region, then search within the options available. Once you’ve selected your location, click NEXT. You must complete this step to proceed.</p>`,
        label: `Location`,
        title: `Location`
      },
      OPTIONAL: {
        image: ``,
        instructions: `<p>You can modify this information at any time in the My Profile section.</p>`,
        title: `Additional info`,
        DOB: {
          disclaimer: ``,
          image: ``,
          instructions: `<p>Please enter your date of birth if you would like to give Chirpy the ability to recognise you on your birthday.</p>`,
          label: `Date of birth (optional)`,
          minAge: 50,
          maxAge: 110,
          title: `Date of birth`
        },
        PHONE: {
          disclaimer: ``,
          image: ``,
          instructions: `<p>Your mobile phone number is important so we can keep you updated and welcome you personally to our new member concierge program. Your phone number is kept private to Chirpy Staff and your local host. </p>`,
          isRequired: true,
          label: `Phone number`,
          title: `Phone`
        }
      },
      PAGES_WHATS_NEXT: {
        title: `You are all set up!`
      },
      progressColor: `primary`,
      showNavigation: true,
      steps: ['display-name', 'gender', 'optional', 'location', 'groups', 'pages-whats-next'],
      useProgressBar: false,
      WHATS_NEXT: {
        buttonLink: ``,
        buttonText: ``,
        footer: `<p>If you need any help, click the pink ? button at the bottom of each page to find the answers to frequently asked questions or email a message to our support team at <a href="mailto:support@chirpyplus.com">support@chirpyplus.com</a>.</p>
        <p>Once again, thank you for becoming a part of the Chirpy family!</p>
        <p>Shaun, Carol and The Chirpy Team</p>`,
        header: ``,
        image: ``,
        options: [
          {
            text: `Thank you for joining the Chirpy family. You can update your profile at any time by clicking the profile icon on the Chirpy app’s <a href="/home">main page</a>.`,
            image: `https://firebasestorage.googleapis.com/v0/b/chirpy-central.appspot.com/o/assets%2Fupdate-profile.gif?alt=media&token=46cc5edc-e261-4c65-9087-f57a110dfd53`
          },
          {
            text: `By default, all your Chirpy notifications are turned on, which means you’ll receive an email for each one. You can turn some (or all) of them off by clicking the My Account icon on the main page and selecting <a href="/account/notifications">Manage my notifications</a> from the account menu. Alternatively, you can click the Notifications button on individual group pages to manage notifications from that group.`,
            image: `https://firebasestorage.googleapis.com/v0/b/chirpy-central.appspot.com/o/assets%2Fupdate-notifications.gif?alt=media&token=c7786587-61ca-42aa-bc48-b824f37d1b60`
          },
          {
            text: `If you love to travel, consider joining the <a href="/groups/50jOCeizq4wGL0genKQE">Chirpy Travel group</a>. We have lots of fantastic cruises and tours planned where you can see the world with a fun group of like-minded Chirpies and save money by sharing accommodation costs.`,
            image: `https://firebasestorage.googleapis.com/v0/b/chirpy-central.appspot.com/o/group%2F50jOCeizq4wGL0genKQE%2Favatar_200x200?generation=1658182353013670&alt=media&cb=1658182359529`
          },
          {
            text: `Local CatchUp groups aren’t your only option. You can also join our Special Interest Groups to chat online about the things you love most, like recipes, motor homing, books, classic cars, gardening, fishing, and more! We even have a group that runs regular quizzes. <a href="/groups">Search the full list</a> and join as many as you like.`,
            image: `https://firebasestorage.googleapis.com/v0/b/chirpy-dev-ac30a.appspot.com/o/assets%2Fsigs_200x200.jpg?alt=media&token=fb70d9e7-1b85-444c-af63-0606c00028c5`
          }
        ],
        title: 'You are all set up!'
      }
    }
  }
};
