import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService, AnalyticsCategory, AnalyticsAction } from '@shared/services/analytics';
import { IBingoGame } from '../models/bingo-game';
import { BingoService } from '../services/bingo.service';
import { BingoHelpNotesComponent } from './components/help-notes/help-notes.component';

@Component({
  selector: 'app-bingo-start',
  templateUrl: 'start.page.html',
  styleUrls: ['start.page.scss']
})
export class StartPage implements OnInit, OnDestroy {
  gameInProgress: IBingoGame;

  onDestory = new Subject();

  constructor(private analyticsService: AnalyticsService, private bingoService: BingoService, private modalController: ModalController, private router: Router) {}

  ngOnDestroy() {
    this.onDestory.next();
    this.onDestory.complete();
  }

  ngOnInit(): void {
    this.bingoService
      .getInProgressGame()
      .pipe(takeUntil(this.onDestory))
      .subscribe(game => (this.gameInProgress = game));
  }

  onResumeGame(gameId: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_RESUME_GAME, gameId);

    this.navigateToGame(gameId);
  }

  async onStartNewGame() {
    // Store this here in case `gameInProgress` is updated when creating
    // the newest game.
    const inProgressGameId = this.gameInProgress && this.gameInProgress.uid;

    // Create the game data
    const game = await this.bingoService.createNewGame();
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_START_GAME, game.uid);

    // If a game was already in progress then end it
    if (inProgressGameId) {
      this.bingoService.endGame(inProgressGameId);
    }

    // Navigate to new game
    this.navigateToGame(game.uid);
  }

  private navigateToGame(gameId: string) {
    this.router.navigate(['/games/bingo/game/', gameId]);
  }

  async presentHelpNotesModal() {
    const modal = await this.modalController.create({
      component: BingoHelpNotesComponent
    });
    return await modal.present();
  }
}
